import styled, { CSSProperties } from "styled-components";
import { NameID } from "common/types/NameID";
import { useEffect, useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { SelectNameIDDlg } from "./SelectNameIDDlg";

type Props = {
  title: string;
  lists: NameID[];
  selecteds: NameID[];
  stringWhenEmpty: string;
  valueWhenEmpty?: NameID;
  readonly?: boolean;
  maxHeight?: string;
  borderAlways?: boolean;
  selectedChanged: (vals: NameID[]) => void;
  style?: CSSProperties;
};

//=====================================
// NameIDのリストを表示する
//=====================================
export const NameIDList = (props: Props) => {
  const {
    title,
    lists,
    selecteds,
    readonly = false,
    maxHeight = "200px",
    borderAlways = true,
    selectedChanged,
    valueWhenEmpty,
    style,
  } = props;
  const [vals, setVals] = useState<NameID[]>([]);
  const [showDlg, setShowDlg] = useState(false);

  const processKeyEvent = (e: React.KeyboardEvent) => {
    if (readonly) return;
    switch (e.key) {
      case "Enter":
      case " ":
        setShowDlg(true);
        break;
    }
  };

  useEffect(() => {
    setVals(selecteds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecteds]);

  return (
    <>
      <ListGroup
        as="ul"
        style={{ maxHeight: maxHeight, overflow: "auto", ...style }}
        tabIndex={0}
        onKeyUp={processKeyEvent}
      >
        {vals.length === 0 && (
          <SListItem
            readOnly={readonly}
            borderAlways={borderAlways}
            onClick={(e: MouseEvent) => {
              if (readonly) return;
              setShowDlg(true);
            }}
          >
            <Form.Label>{props.stringWhenEmpty}</Form.Label>
          </SListItem>
        )}
        {vals.map((val) => (
          <SListItem
            key={`val_${val.id}`}
            borderAlways={borderAlways}
            readOnly={readonly}
            onClick={() => {
              if (readonly) return;
              setShowDlg(true);
            }}
          >
            <Form.Label>{val.name}</Form.Label>
          </SListItem>
        ))}
      </ListGroup>
      <SelectNameIDDlg
        title={title}
        show={showDlg}
        lists={lists}
        selecteds={vals}
        onOK={(vals) => {
          let newVals = vals;
          if (valueWhenEmpty) {
            // 設定なしの時には他の値を見せる
            // (事故通報でチェックが無い場合、通報無し＝3となる場合)
            const id4empty = valueWhenEmpty.id;
            if (newVals.length === 0) {
              newVals = [valueWhenEmpty];
            } else {
              newVals = newVals.filter((x) => x.id !== id4empty);
            }
          }
          setVals(newVals);
          selectedChanged(newVals);
          setShowDlg(false);
        }}
        onCancel={() => setShowDlg(false)}
      />
    </>
  );
};

const SListItem = styled(ListGroup.Item)<{ readOnly: boolean; borderAlways: boolean }>`
  cursor: ${(props) => (props.readOnly ? "default" : "pointer")};
  border-style: ${(props) => (!props.readOnly || props.borderAlways ? "solid" : "none")};
  margin: 0;
  vertical-align: baseline;
  padding-top: 2px;
  padding-bottom: 2px;
  label {
    cursor: ${(props) => (props.readOnly ? "default" : "pointer")};
    padding: 0.5rem 0;
    margin: 0;
  }
`;
