import styled from "styled-components";
import { StaffInfo, Staffs } from "common/types/Staffs";
import { useEffect, useRef, useState } from "react";
import { Form, ListGroup, Modal } from "react-bootstrap";
import DraggableModal from "common/components/molecules/DraggableModal";
import { ModalButtons } from "common/components/molecules/ModalButtons";

type Props = {
  show: boolean;
  lists: Staffs;
  data: Staffs;
  onOK: (vals: Staffs) => void;
  onCancel: () => void;
};

export const SelectStaffsDlg = (props: Props) => {
  const { show, lists, onOK, onCancel } = props;
  const nodeRef = useRef();
  const [fulltimes, setFulltimes] = useState<StaffInfo[]>([]);
  const [parttimes, setParttimes] = useState<StaffInfo[]>([]);
  const [tempStaffs, setTempstaffs] = useState<StaffInfo[]>([]);
  const [data, setData] = useState<Staffs>();

  useEffect(() => {
    setData({ ...props.data });
    setFulltimes(
      props.data.fulltime.map((x) => {
        return { ...x };
      })
    );
    setParttimes(
      props.data.parttime.map((x) => {
        return { ...x };
      })
    );
    setTempstaffs(
      props.data.tempstaff.map((x) => {
        return { ...x };
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  if (!data) return <div>不正な状態</div>;

  return (
    <Modal show={show} variant="info" ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title> 巡回担当者</Modal.Title>
      </Modal.Header>
      <SModalBody>
        <ListGroup as="ul" style={{ overflow: "auto" }}>
          {lists?.fulltime &&
            lists.fulltime.map((full) => (
              <ListGroup.Item key={`full_${full.employeeID}`}>
                <Form.Check
                  label={full.employeeName}
                  checked={fulltimes.find((x) => x.employeeID === full.employeeID) !== undefined}
                  onChange={(e) => {
                    if (e.target.checked) {
                      data.fulltime = [...data.fulltime, full];
                    } else {
                      data.fulltime = data.fulltime.filter((x) => x.employeeID !== full.employeeID);
                    }
                    setFulltimes(data.fulltime);
                  }}
                />
              </ListGroup.Item>
            ))}
          {lists?.parttime &&
            lists.parttime.map((parttime) => (
              <ListGroup.Item key={`part_${parttime.employeeID}`}>
                <Form.Check
                  label={parttime.employeeName}
                  checked={
                    parttimes.find((x) => x.employeeID === parttime.employeeID) !== undefined
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      data.parttime = [...data.parttime, parttime];
                    } else {
                      data.parttime = data.parttime.filter(
                        (x) => x.employeeID !== parttime.employeeID
                      );
                    }
                    setParttimes(data.parttime);
                  }}
                />
              </ListGroup.Item>
            ))}
          {lists?.tempstaff &&
            lists.tempstaff.map((tempStaff) => (
              <ListGroup.Item key={`temp_${tempStaff.employeeID}`}>
                <Form.Check
                  label={tempStaff.employeeName}
                  checked={
                    tempStaffs.find((x) => x.employeeID === tempStaff.employeeID) !== undefined
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      data.tempstaff = [...data.tempstaff, tempStaff];
                    } else {
                      data.tempstaff = data.tempstaff.filter(
                        (x) => x.employeeID !== tempStaff.employeeID
                      );
                    }
                    setTempstaffs(data.tempstaff);
                  }}
                />
              </ListGroup.Item>
            ))}
        </ListGroup>
      </SModalBody>
      <Modal.Footer>
        <ModalButtons
          onOk={() => {
            onOK(data);
          }}
          okText="OK"
          onCancel={onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

const SModalBody = styled(Modal.Body)`
  max-height: 350px;
  overflow: auto;
`;
