import { memo, useContext, useEffect, useState } from "react";
import { Col, Row, Stack, ToastContainer } from "react-bootstrap";
import styled from "styled-components";

import StatusContext from "common/store/StatusContext";

import MyAlert from "../atoms/MyAlert";

import ILSidebar from "../organisms/ILSidebar";
import Topbar from "../organisms/Topbar";
import Footer from "../organisms/Footer";
import MyToast from "../atoms/MyToast";

type Props = {
  children: React.ReactNode;
};

export const DashboardTemplate = memo((props: Props) => {
  const { errorMessage, setErrorMessage, toastTitle, toastMessage, setToastMessage } =
    useContext(StatusContext);

  const [sideBarOpen, setSideBarOpen] = useState(true);

  useEffect(() => {
    setErrorMessage("");
  }, [setErrorMessage]);

  return (
    <>
      <Row className="fixed-top" style={{ zIndex: 3 }}>
        <Topbar onHumbergerClick={() => setSideBarOpen(!sideBarOpen)} />
      </Row>
      <ContentRow>
        {sideBarOpen && (
          <SidebarCol>
            <ILSidebar className="px-2 ms-2" />
          </SidebarCol>
        )}
        <MainContent className={`${!sideBarOpen ? "full" : ""}`}>{props.children}</MainContent>
      </ContentRow>
      <div className="fixed-bottom" style={{ zIndex: 1, pointerEvents: "none" }}>
        <Footer />
      </div>
      <ToastContainer className="p-3 fixed-top ms-auto" position="top-end" style={{ zIndex: 99 }}>
        {toastMessage && (
          <MyToast
            variant="success"
            title={toastTitle}
            message={toastMessage}
            duration={3000}
            onClose={async () => setToastMessage("")}
          ></MyToast>
        )}
      </ToastContainer>
      <SMessageStack className="fixed-top">
        {errorMessage && (
          <MyAlert variant="danger" closeRequested={() => setErrorMessage("")}>
            {errorMessage}
          </MyAlert>
        )}
      </SMessageStack>
    </>
  );
});

const ContentRow = styled(Row)`
  margin-left: 10px;
  height: 90vh;
  width: 98vw;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 65vh;
  }
`;

const SidebarCol = styled(Col)`
  z-index: 2;
  margin-left: -15px;
  padding: 0px 0px;
  padding-top: 58px;
  @media screen and (max-width: 768px) {
    padding: 0px;
    width: 100%;
  }
`;

const MainContent = styled.div`
  padding-top: 58px;
  width: calc(100% - 250px);
  z-index: 0;
  @media screen and (max-width: 768px) {
    padding: 0 5px;
    width: 100%;
  }
  &.full {
    width: 100%;
  }
`;

const SMessageStack = styled(Stack)`
  margin-top: 56px;
`;
