import { createRef, useContext, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";
import { PageTop } from "common/components/organisms/PageTop";
import { InputTextDlg } from "common/components/organisms/InputTextDlg";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { useFileUtils } from "common/hooks/useFileUtils";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { createDocumentTitle } from "utils/commonTools";

import { ParkEventPanel } from "./components/ParkEventPanel";
import { useParkEvents } from "./hooks/useParkEvents";
import { useParkEventData } from "./hooks/useParkEventData";
import { ParkEventMeta } from "./types/ParkEventMeta";
import { ParkEvent } from "./types/ParkEventData";
import { IndependentEvent, CollaborateEvent } from "./types/ParkEventSpecific";
import { EVENT_TYPE } from "./types/EVENT_TYPE";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";

let isInitializing = false;

//=====================================
// 自主事業・協働事業ページ
//=====================================
export const ParkEventsPage = (props: { pageTitle: string; eventType: EVENT_TYPE }) => {
  const { pageTitle, eventType } = props;
  const eventSpecific = eventType === EVENT_TYPE.Independent ? IndependentEvent : CollaborateEvent;

  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);
  const { selectedPark, readOnly } = useContext(AuthContext);
  const [metaData, setMetaData] = useState<ParkEventMeta | null>(null);
  const { loadMetaData, getReportData, saveReportData } = useParkEvents();
  const { createEmptyParkEvent, resolveParticipants } = useParkEventData();
  const { processPictures } = useFileUtils();

  const [events, setEvents] = useState<ParkEvent[]>([]);
  const [deletedEvents, setDeletedEvents] = useState<ParkEvent[]>([]);

  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);

  const [activeTab, setActiveTab] = useState<string>("");
  const [titleTarget, setTitleTarget] = useState<ParkEvent | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<ParkEvent | null>(null);
  const eventRef = createRef<ForceCloseHandler>();

  const { id } = useParams<{ id?: string }>();
  const categoryId = id === undefined ? undefined : +id;

  const getTitle = () => {
    const tabId = eventType === EVENT_TYPE.Independent ? TAB_ID.PRIVATE_BIZ : TAB_ID.PUBLIC_BIZ;
    return (
      selectedPark?.dailyTabs.find((x) => x.id === tabId && x.categoryId === categoryId)?.name ??
      pageTitle
    );
  };

  const title = getTitle();
  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<ParkEventMeta | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(
      selectedPark.parkId,
      eventSpecific.EventType
    );

    setIsLoading(false);

    if (succeeded) {
      setMetaData(data!);
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 設定されている日付の日報データをロードする
  //--------------------------------------
  const loadReportData = async (baseData: ParkEventMeta) => {
    if (!selectedPark) return;

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      eventSpecific.EventType,
      currentDate,
      categoryId
    );
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg || "");
    }
    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );
    if (data) {
      setEvents(data.events.map((x) => resolveParticipants(x, baseData.participantTypes)));
      setActiveTab(getTabId(0));
    } else {
      setEvents([]);
    }
  };

  //--------------------------------------
  // 更新処理の実行
  //--------------------------------------
  const onSave = async () => {
    await execSave(true);
  };

  const execSave = async (showToast?: boolean) => {
    if (!selectedPark) return false;

    // ダイアログを開いている時に編集権限譲渡リクエストが来た場合
    if (titleTarget) setTitleTarget(null);
    if (deleteTarget) setDeleteTarget(null);
    eventRef.current?.forceClose();

    setErrorMessage("");
    setIsLoading(true);

    setLoadingMessage("ファイルの登録・削除中");

    // イベントごと削除した場合、登録済みのファイルを消す必要がある
    deletedEvents.forEach((ev) => {
      ev.pictures.forEach((pic) => (pic.deleted = true));
    });
    await Promise.all(deletedEvents.map((ev) => processPictures(selectedPark.parkId, ev.pictures)));

    // イベントに対して新しく追加・削除したファイルはここでサーバーに登録する
    await Promise.all(events.map((ev) => processPictures(selectedPark.parkId, ev.pictures)));

    events.forEach((ev) => (ev.pictures = ev.pictures.filter((x) => !x.deleted)));

    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      eventSpecific,
      currentDate,
      { events },
      title,
      categoryId
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }
      setDeletedEvents([]);
      if (showToast) showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
    return succeeded;
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(title);
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData(baseData);
    };
    f();
  }, [currentDate, title, eventType]); // eslint-disable-line react-hooks/exhaustive-deps

  //--------------------------------------
  // 事業の追加が押された時の処理 -> 事業名を入れるダイアログを表示
  //--------------------------------------
  const addEventRequested = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    var newEvent = createEmptyParkEvent(eventType, metaData?.participantTypes);
    if (id !== undefined) newEvent.categoryId = +id;
    setTitleTarget(newEvent);
  };

  //--------------------------------------
  // 事業名を入れるダイアログが閉じられた時の処理
  //--------------------------------------
  const setTitleCompleted = (title: string) => {
    if (!titleTarget) return;

    titleTarget.title = title;

    var targetIndex = events?.findIndex((val) => val === titleTarget);
    let newEvents = events;
    if (targetIndex === undefined || targetIndex < 0) {
      // 追加
      if (!newEvents) newEvents = [];
      newEvents.push(titleTarget);
      targetIndex = newEvents.length - 1;
    } else {
      // 更新
      newEvents![targetIndex] = titleTarget;
    }

    setEvents([...newEvents!]);
    setTitleTarget(null);
    setActiveTab(getTabId(targetIndex));
  };

  //--------------------------------------
  // 削除の実行
  //--------------------------------------
  const confirmDeleteOK = async () => {
    if (!deleteTarget) return;

    var newEvents = events?.filter((val) => val !== deleteTarget);
    if (newEvents) {
      setEvents(newEvents);
      if (newEvents.length >= 0) setActiveTab(getTabId(0));
    }

    setDeletedEvents([...deletedEvents, deleteTarget]);
    setDeleteTarget(null);
  };

  //--------------------------------------
  // タブIDの生成
  //--------------------------------------
  const getTabId = (index: number) => `event_${index}`;

  const reloadData = async () => {
    if (metaData) await loadReportData(metaData);
  };

  return (
    <DashboardTemplate>
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={[`事業名"${deleteTarget.title}"を削除しても良いですか`]}
          onOk={confirmDeleteOK}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}
      <FormContainer size="max">
        {titleTarget && (
          <InputTextDlg
            show={titleTarget !== null}
            initialValue={titleTarget.title}
            title={`'${title}'の事業名`}
            message={"事業名を入力してください"}
            onOk={(title) => {
              setTitleCompleted(title);
            }}
            onCancel={() => setTitleTarget(null)}
          />
        )}
        {!selectedPark && <div> 公園が選択されていません </div>}
        <PageTop
          title={title}
          reportType={REPORT_TYPE.DAILY}
          tabId={eventType === EVENT_TYPE.Independent ? TAB_ID.PRIVATE_BIZ : TAB_ID.PUBLIC_BIZ}
          lastUpdated={lastUpdated}
          onEditStart={async () => await reloadData()}
          onSave={!readOnly ? onSave : undefined}
          onCancel={async () => await reloadData()}
        />
        <BaseForm className="p-3">
          <Tabs
            activeKey={activeTab}
            onSelect={(e) => {
              setActiveTab(e!);
            }}
          >
            {events &&
              events.map((parkEvent, index) => (
                <Tab
                  title={parkEvent.title}
                  key={`parkeventtab_${index}`}
                  eventKey={getTabId(index)}
                >
                  <ParkEventPanel
                    data={parkEvent}
                    eventType={eventType}
                    readonly={!isEditing}
                    dataUpdated={(data) => {
                      events[index] = data;
                    }}
                    editTitleRequested={(data) => {
                      setTitleTarget(data);
                    }}
                    deleteDataRequested={(data) => {
                      setDeleteTarget(data);
                    }}
                    saveDataRequested={execSave}
                    ref={activeTab === getTabId(index) ? eventRef : undefined}
                  />
                </Tab>
              ))}
            {isEditing && (
              <Tab
                title={
                  <div className="fas fa-plus" onClick={addEventRequested}>
                    <span className="ps-1">追加</span>
                  </div>
                }
              ></Tab>
            )}
          </Tabs>
        </BaseForm>
      </FormContainer>
    </DashboardTemplate>
  );
};
