import { createRef, useContext, useEffect, useState } from "react";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { createDocumentTitle } from "utils/commonTools";

import { useIncidents } from "./hooks/useIncidents";
import { useIncidentData } from "./hooks/useIncidentData";
import { useFileUtils } from "common/hooks/useFileUtils";

import { IncidentDetail, IncidentTotal } from "./types/IncidentReport";

import { PageTop } from "common/components/organisms/PageTop";
import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import { BaseForm } from "common/components/templates/BaseForm";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { IncidentTotalTable } from "./components/IncidentTotalTable";
import { IncidentDetailTable } from "./components/IncidentDetailTable";
import { IncidentMeta } from "./types/IncidentMeta";
import { DataEditTarget } from "common/types/DataEditTarget";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";
import { EditIncidentDlg } from "./components/EditIncidentDlg";
import { FileListCards } from "common/components/organisms/files/FileListCards";
import {
  FILE_ACCEPTS_ALL,
  FILE_ACCEPTS_IMAGES,
  REPORT_TYPE,
  TAB_ID,
} from "common/types/consts/Defines";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";

let isInitializing = false;

const IncidentPage = (props: { pageTitle: string }) => {
  const { pageTitle } = props;

  const { selectedPark, readOnly } = useContext(AuthContext);
  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);
  const { loadMetaData, getReportData, saveReportData } = useIncidents();
  const { createEmptyTotals, createEmptyData, updateTotals } = useIncidentData();
  const { processPictures } = useFileUtils();

  const [metaData, setMetaData] = useState<IncidentMeta | null>(null);

  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);
  const [totals, setTotals] = useState<IncidentTotal[]>([]);
  const [details, setDetails] = useState<IncidentDetail[]>([]);

  const [files, setFiles] = useState<RegisteredFileInfo[] | null>([]);
  const [pictures, setPictures] = useState<RegisteredFileInfo[] | null>([]);

  const [editTarget, setEditTarget] = useState<DataEditTarget<IncidentDetail> | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<IncidentDetail | null>(null);

  const fileListRef = createRef<ForceCloseHandler>();
  const pictureListRef = createRef<ForceCloseHandler>();

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<IncidentMeta | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);

    setIsLoading(false);

    if (succeeded) {
      setMetaData(data!);
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 設定されている日付の日報データをロードする
  //--------------------------------------
  const loadReportData = async () => {
    if (!selectedPark) return;

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      currentDate
    );
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg || "");
    }
    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );

    const totals = createEmptyTotals();
    if (data) {
      updateTotals(totals, data.details);
    }
    setTotals(totals);
    setDetails(data?.details ?? []);
    setPictures(data?.pictures ?? null);
    setFiles(data?.files ?? null);
  };

  //--------------------------------------
  // データの追加
  //--------------------------------------
  const addDataRequested = () => {
    const newData = createEmptyData();
    setEditTarget({ index: -1, data: newData });
  };

  //--------------------------------------
  // データの編集
  //--------------------------------------
  const editDataRequested = (data: IncidentDetail, index: number) => {
    setEditTarget({ index, data: { ...data } });
  };

  const addOrEditCompleted = (data: IncidentDetail) => {
    if (!editTarget) return;

    let newArray = details;
    if (!newArray) newArray = [];

    if (editTarget.index < 0) {
      // 追加
      newArray?.push(data);
      setDetails(newArray);
    } else {
      // 編集
      newArray![editTarget.index] = data;
      setDetails(newArray);
    }

    setTotals(updateTotals(totals!, newArray));

    setEditTarget(null);
  };

  //--------------------------------------
  // データの削除
  //--------------------------------------
  const deleteDataRequested = (data: IncidentDetail) => {
    setDeleteTarget(data);
  };

  const confirmDeleteOK = async () => {
    var newList = details!.filter((x) => x !== deleteTarget);
    setDetails(newList);
    setTotals(updateTotals(totals!, newList));
    setDeleteTarget(null);
  };

  //--------------------------------------
  // 保存
  //--------------------------------------
  const onSave = async () => {
    if (!selectedPark || !totals) return;

    // ダイアログを開いている時に編集権限譲渡リクエストが来た場合
    if (editTarget) setEditTarget(null);
    if (deleteTarget) setDeleteTarget(null);
    fileListRef.current?.forceClose();
    pictureListRef.current?.forceClose();

    if (files) {
      await processPictures(selectedPark.parkId, files);
    }

    if (pictures) {
      await processPictures(selectedPark.parkId, pictures);
    }

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const filesToSave = files?.filter((x) => !x.deleted) ?? [];
    const picturesToSave = pictures?.filter((x) => !x.deleted) ?? [];
    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      currentDate,
      {
        totals,
        details,
        files: filesToSave,
        pictures: picturesToSave,
      },
      pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }

      showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
  };

  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData();
    };
    f();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <DashboardTemplate>
      {!selectedPark && <div> 公園が選択されていません </div>}
      <PageTop
        title={pageTitle}
        reportType={REPORT_TYPE.DAILY}
        tabId={TAB_ID.INCIDENTS}
        lastUpdated={lastUpdated}
        onEditStart={async () => await loadReportData()}
        onSave={!readOnly ? onSave : undefined}
        onCancel={async () => await loadReportData()}
      />
      <BaseForm>
        <IncidentTotalTable data={totals} />
        <IncidentDetailTable
          details={details}
          readonly={!isEditing}
          addDataRequested={addDataRequested}
          editDataRequested={editDataRequested}
          deleteDataRequested={deleteDataRequested}
        />
        <hr />
        <FileListCards
          className="pb-3 px-2"
          attr={{
            title: "写真",
            accepts: FILE_ACCEPTS_IMAGES,
            usePicture: true,
            imgHeight: "10rem",
          }}
          ref={pictureListRef}
          files={pictures}
          readonly={!isEditing}
          listChanged={(newFiles) => setPictures(newFiles)}
        />
        <hr />
        <FileListCards
          className="pb-3 px-2"
          attr={{ title: "ファイル", accepts: FILE_ACCEPTS_ALL, usePicture: false }}
          ref={fileListRef}
          files={files}
          readonly={!isEditing}
          listChanged={(newFiles) => setFiles(newFiles)}
        />
      </BaseForm>
      {metaData && editTarget && (
        <EditIncidentDlg
          target={editTarget}
          metaData={metaData}
          onOK={addOrEditCompleted}
          onCancel={() => setEditTarget(null)}
        />
      )}
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={["選択したデータを削除しても良いですか"]}
          onOk={confirmDeleteOK}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}
    </DashboardTemplate>
  );
};

export default IncidentPage;
