import styled from "styled-components";
import { NameID } from "common/types/NameID";
import { useEffect, useRef, useState } from "react";
import { Form, ListGroup, Modal } from "react-bootstrap";
import DraggableModal from "./DraggableModal";
import { ModalButtons } from "./ModalButtons";

type Props = {
  show: boolean;
  title: string;
  lists: NameID[];
  selecteds: NameID[];
  onOK: (vals: NameID[]) => void;
  onCancel: () => void;
};

export const SelectNameIDDlg = (props: Props) => {
  const { show, title, onOK, onCancel } = props;
  const nodeRef = useRef();
  const [selecteds, setSelecteds] = useState<NameID[]>([]);

  useEffect(() => {
    setSelecteds([...props.selecteds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Modal show={show} variant="info" centered ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <SModalBody>
        <ListGroup as="ul" style={{ overflow: "auto" }}>
          {props.lists.map((val) => (
            <ListGroup.Item key={`val_${val.id}`}>
              <Form.Check
                label={val.name}
                checked={selecteds.find((x) => x.id === val.id) !== undefined}
                onChange={(e) => {
                  let newVals = e.target.checked
                    ? [...selecteds, val]
                    : selecteds.filter((x) => x.id !== val.id);
                  setSelecteds(newVals);
                }}
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </SModalBody>
      <Modal.Footer>
        <ModalButtons onOk={() => onOK(selecteds)} onCancel={onCancel} />
      </Modal.Footer>
    </Modal>
  );
};

const SModalBody = styled(Modal.Body)`
  max-height: 350px;
  overflow: auto;
  padding: 0.5rem;
`;
