import { RefObject } from "react";
import Draggable, { DraggableEvent } from "react-draggable";
import ModalDialog, { ModalDialogProps } from "react-bootstrap/ModalDialog";

export interface DraggableModalDialogProps extends ModalDialogProps {
  nodeRef?: RefObject<HTMLElement>;
}

const DraggableModal = ({ nodeRef, ...props }: DraggableModalDialogProps) => {
  const onDrag = (e: DraggableEvent) => {
    if (e instanceof MouseEvent) {
      // TODO: constrain to window bounds
    }
  };

  return (
    <Draggable nodeRef={nodeRef} onDrag={onDrag} handle=".handle">
      <ModalDialog {...props} style={{ transition: "none" }} />
    </Draggable>
  );
};

export default DraggableModal;
