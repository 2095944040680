import { CancelButton } from "../atoms/CancelButton";
import PrimaryButton from "../atoms/PrimaryButton";

type Props = {
  onOk: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
  okDisabled?: boolean;
};

export const ModalButtons = (props: Props) => {
  return (
    <>
      <PrimaryButton
        className="mx-2"
        onClick={props.onOk}
        title={props.okText || "OK"}
        style={{ minWidth: "100px" }}
        disabled={props.okDisabled}
      />
      <CancelButton
        onClick={props.onCancel}
        text={props.cancelText}
        style={{ minWidth: "100px" }}
      />
    </>
  );
};
