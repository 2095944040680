import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import FunctionButton from "common/components/atoms/FunctionButton";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import { v4 as uuidv4 } from "uuid";

import { FileCard } from "./FileCard";
import { AddFileDlg } from "./AddFileDlg";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";
import { EditFileInfoDlg } from "./EditFileInfoDlg";
import { ShowEmpty } from "common/components/atoms/ShowEmpty";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";

export type FileCardsAttr = {
  title: string;
  accepts: string; // ファイル追加ダイアログでのフィルター
  usePicture: boolean; // イメージ表示するか
  hasComment?: boolean; // コメントは必要か
  imgHeight?: string; // 1枚当たりのサイズ
};

type Props = {
  files: Array<RegisteredFileInfo> | null;
  attr: FileCardsAttr;
  readonly: boolean;
  listChanged: (files: Array<RegisteredFileInfo> | null) => void;
  className?: string;
  isInModal?: boolean; // モーダルに埋め込む場合の挙動をOnにする
};

// ------------------------------------------------------------------
// ファイル一覧表示
//-------------------------------------------------------------------
export const FileListCards = forwardRef<ForceCloseHandler, Props>((props: Props, ref) => {
  const { files, attr, readonly, listChanged, isInModal = false } = props;

  const [showAddFileDlg, setShowAddFileDlg] = useState(false);
  const [editTarget, setEditTarget] = useState<RegisteredFileInfo | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<RegisteredFileInfo | null>(null);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState<string[]>();

  useImperativeHandle(ref, () => ({
    forceClose() {
      if (showAddFileDlg) setShowAddFileDlg(false);
      if (editTarget) setEditTarget(null);
      if (deleteTarget) setDeleteTarget(null);
    },
  }));
  //--------------------------------------
  // ファイルの追加が完了した時
  //--------------------------------------
  const onFileAdd = async (title: string, comment: string, file: File) => {
    var newData = { title, fileName: file.name, comment, file, fileId: -1, tempId: uuidv4() };
    if (!files) listChanged([newData]);
    else listChanged([...files, newData]);

    setShowAddFileDlg(false);
  };

  //--------------------------------------
  // ファイルの編集が押された時の処理
  //--------------------------------------
  const onEditRequested = (file: RegisteredFileInfo) => {
    setEditTarget(file);
  };

  //--------------------------------------
  // ファイルの編集が完了した時の処理
  //--------------------------------------
  const onEditCompleted = (fileTitle: string, comment?: string) => {
    if (!editTarget) return;

    editTarget.title = fileTitle;
    editTarget.comment = comment;

    setEditTarget(null);

    listChanged([...files!]);
  };

  //--------------------------------------
  // ファイルの削除が押された時の処理
  //--------------------------------------
  const onDeleteRequested = (file: RegisteredFileInfo) => {
    setDeleteTarget(file);
    setDeleteConfirmationMessage([`${file.title}(${file.fileName})を削除してもよろしいですか`]);
  };

  //--------------------------------------
  // ファイルの削除確認でOKが押された時の処理
  //--------------------------------------
  const onDeleteOK = () => {
    if (!deleteTarget) return;

    if (deleteTarget.file) {
      // 新規追加し、保存前に削除した場合
      listChanged(files?.filter((x) => x !== deleteTarget) ?? null);
    } else {
      deleteTarget.deleted = true;
      listChanged([...files!]);
    }
    setDeleteTarget(null);
  };

  useEffect(() => {}, [props.files]);

  return (
    <>
      <div className={props?.className}>
        <div className="d-flex justify-content-between align-items-center">
          <span className="h5">{!isInModal && attr.title}</span>
          {!readonly && (
            <FunctionButton
              onClick={(e) => {
                e.preventDefault();
                setShowAddFileDlg(true);
              }}
              className="mx-0 mb-2"
              tooltip="追加"
            >
              <i className="fa-regular fa-plus"></i>
            </FunctionButton>
          )}
        </div>
        {!files || !files.find((x) => !x.deleted) ? (
          <ShowEmpty>{`${attr.title}は登録されていません`}</ShowEmpty>
        ) : (
          <div className="d-flex flex-wrap gap-2">
            {files
              .filter((x) => !x.deleted)
              .map((file) => {
                return (
                  <div
                    className="align-self-start me-2"
                    key={`file_${file.fileId >= 0 ? file.fileId : file.tempId}`}
                  >
                    <FileCard
                      file={file}
                      imgHeight={attr.imgHeight}
                      hasComment={attr.hasComment}
                      showPicture={attr.usePicture}
                      editRequested={() => onEditRequested(file)}
                      deleteRequeseted={() => onDeleteRequested(file)}
                      readOnly={readonly}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {editTarget && (
        <EditFileInfoDlg
          show={editTarget !== null}
          fileInfo={editTarget}
          title={attr.title}
          hasComment={attr.hasComment}
          onOk={onEditCompleted}
          onCancel={() => setEditTarget(null)}
        />
      )}
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title={`${attr.title}の削除`}
          messages={deleteConfirmationMessage}
          onOk={async () => onDeleteOK()}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}
      {showAddFileDlg && (
        <AddFileDlg
          show={showAddFileDlg}
          title={attr.title}
          accepts={attr.accepts}
          hasComment={attr.hasComment}
          onCancel={() => setShowAddFileDlg(false)}
          onOk={onFileAdd}
        />
      )}
    </>
  );
});
