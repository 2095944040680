import { useState } from "react";
import styled from "styled-components";
import { Form, Table } from "react-bootstrap";

import { NameID } from "common/types/NameID";
import { NameIDList } from "common/components/molecules/NameIDList";

import { useAreas } from "common/hooks/useAreas";

import MyTextArea from "common/components/atoms/MyTextArae";
import MyCheckbox from "common/components/atoms/MyCheckbox";
import MyNumberInput from "common/components/atoms/MyNumberInput";

import { CheckListAnswer, CheckListItem } from "../types/WorkReportData";
import { MultiLineText } from "common/components/atoms/MultiLineText";
import FunctionButton from "common/components/atoms/FunctionButton";

type Props = {
  metaLists: NameID[];
  checkList: CheckListItem[];
  areas: NameID[];
  readonly: boolean;
  addAnswerRequested: (item: CheckListItem) => void;
  removeAnswerRequested: (answer: CheckListAnswer, item: CheckListItem) => void;
};
export const CheckListPanel = (props: Props) => {
  const { metaLists, checkList, areas, readonly } = props;
  const { toAreaString } = useAreas();

  const [allChecked, setAllChecked] = useState(false);

  const getListBody = (ans: CheckListAnswer, item: CheckListItem, isFirst: boolean) => {
    return (
      <>
        <td>
          {readonly ? (
            <MultiLineText text={ans.memo} />
          ) : (
            <SMyTextArea
              readonly={readonly}
              initialValue={ans.memo}
              borderAlways={false}
              changed={(value) => (ans.memo = value)}
            />
          )}
        </td>
        <td style={{ alignContent: "center" }}>
          {!readonly ? (
            <NameIDList
              title="地区"
              lists={areas}
              selecteds={ans.areas}
              readonly={readonly}
              selectedChanged={(vals) => (ans.areas = vals)}
              stringWhenEmpty={""}
              borderAlways={false}
            />
          ) : (
            <MultiLineText text={toAreaString(ans.areas)} />
          )}
        </td>
        <td>
          {readonly ? (
            <MultiLineText text={ans.areaDetail} />
          ) : (
            <SMyTextArea
              readonly={readonly}
              initialValue={ans.areaDetail}
              changed={(value) => (ans.areaDetail = value)}
              borderAlways={false}
            />
          )}
        </td>
        <td style={{ alignContent: "center", padding: 0 }}>
          <MyNumberInput
            initialValue={ans.count}
            readonly={readonly}
            changed={(value) => (ans.count = value)}
            style={{ padding: "0.4rem 0", fontSize: "14px" }}
          />
        </td>
        {!readonly && (
          <td style={{ alignContent: "center" }}>
            {isFirst ? (
              <FunctionButton
                tooltip="行を追加"
                size="sm"
                onClick={() => props.addAnswerRequested(item)}
              >
                <i className="fa fa-plus" />
              </FunctionButton>
            ) : (
              <FunctionButton
                tooltip="行を削除"
                size="sm"
                onClick={() => props.removeAnswerRequested(ans, item)}
              >
                <i className="fas fa-minus" />
              </FunctionButton>
            )}
          </td>
        )}
      </>
    );
  };

  const onCheckListChanged = () => {
    setAllChecked(checkList.every((x) => x.checked));
  };

  const onAllCheckChanged = (value: boolean) => {
    checkList.forEach((x) => (x.checked = value));
    setAllChecked(value);
  };

  return (
    <RootDiv>
      <STable bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th style={{ width: "40px" }}>
              {!readonly && (
                <Form.Check
                  checked={allChecked}
                  onChange={(e) => onAllCheckChanged(e.currentTarget?.checked)}
                />
              )}
            </th>
            <th>No</th>
            <th className="col-md-*">項目</th>
            <th className="col-md-*">備考</th>
            <th className="col-md-2">地区</th>
            <th className="col-md-2">地区詳細</th>
            <th className="col-md-1">件数</th>
            {!readonly && <th style={{ minWidth: "50px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {metaLists.map((meta) => {
            const chk = checkList.find((x) => x.no === meta.id);
            if (!chk) return <></>;
            chk.title = meta.name; // 項目のテキストが変わっている場合アップデートする
            return chk.results.map((res, index) => (
              <tr key={`chklst_${chk.no}_${index}`}>
                {index === 0 && (
                  <>
                    <TdCenter rowSpan={chk.results.length}>
                      <MyCheckbox
                        readonly={readonly}
                        initialValue={chk.checked}
                        changed={(value) => {
                          chk.checked = value;
                          onCheckListChanged();
                        }}
                      />
                    </TdCenter>
                    <TdCenter rowSpan={chk.results.length}>{chk.no}</TdCenter>
                    <td rowSpan={chk.results.length} style={{ alignContent: "center" }}>
                      {chk.title}
                    </td>
                  </>
                )}
                {getListBody(res, chk, index === 0)}
              </tr>
            ));
          })}
        </tbody>
      </STable>
    </RootDiv>
  );
};

const RootDiv = styled.div`
  width: 100%;
`;

const STable = styled(Table)`
  td {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    vertical-align: middle;
  }
`;

const TdCenter = styled.td`
  vertical-align: middle;
  text-align: center;
`;

const SMyTextArea = styled(MyTextArea)`
  font-size: 14px;
`;
