import { CheckListItem, Task, WorkReportData, WorkReports } from "../types/WorkReportData";
import { INVALID_ID } from "common/types/consts/Defines";
import { Period, WorkReportMeta } from "../types/WorkReportMeta";
import { NameID } from "common/types/NameID";

export const useWorkReportData = () => {
  const createDeletedPeriod = (period: Period): Period => {
    return { ...period, isDeleted: true };
  };

  //--------------------------------------
  // メタデータの過不足を実データを使い更新する
  //--------------------------------------
  const updateMeta = (meta: WorkReportMeta, data?: WorkReports): WorkReportMeta => {
    // 削除されている時間帯を探す
    const deleteds = data?.reports.filter(
      (wr) => wr.period.id !== -1 && !meta.periods.find((peri) => peri.id === wr.period.id)
    );

    // 前回の削除された時間帯があれば取り除き、削除されている時間帯があれば追加する
    return {
      ...meta,
      periods: [
        ...meta.periods.filter((x) => !x.isDeleted),
        ...(deleteds?.map((del) => createDeletedPeriod(del.period)) ?? []),
      ],
    };
  };

  //--------------------------------------
  // 空の有料施設データを作成する
  //--------------------------------------
  const createEmptyReport = (period: Period): WorkReportData => {
    return {
      period: { id: period?.id ?? -1, name: period.name },
      staffs: { fulltime: [], parttime: [], tempstaff: [] },
      patrolMethods: [],
      patrolAreas: [],
      patrolAreaMemo: "",
      warningTypes: [],
      anomalyDetected: false,
      patrolMemo: "",
      checkLists: [],
      tasks: [],
      pictures: [],
    };
  };

  //--------------------------------------
  // 空のチェックリスト一覧を作成する
  //--------------------------------------
  const createCheckLists = (checks: NameID[]): CheckListItem[] => {
    return checks.map(
      (check) =>
        ({
          checked: false,
          no: check.id,
          title: check.name,
          results: [
            {
              areas: [],
              areaDetail: "",
              memo: "",
              count: 0,
            },
          ],
        } as CheckListItem)
    );
  };

  //--------------------------------------
  // チェックリストのコピーを作成する
  //--------------------------------------
  const addAnAnswer = (checkItem: CheckListItem): CheckListItem => {
    checkItem.results.push({
      areas: [],
      areaDetail: "",
      memo: "",
      count: 0,
    });
    return { ...checkItem };
  };

  //--------------------------------------
  // 空の作業データを作成する
  //--------------------------------------
  const createEmptyTask = (): Task => {
    return {
      taskType: { name: "", id: INVALID_ID },
      plannedAmount: 0,
      actualAmount: 0,
      unitOfTask: "",
      taskContent: "",
      contractType: { name: "", id: INVALID_ID },
      contractorName: "",
      areas: [],
      pictures: [],
    };
  };
  return {
    updateMeta,
    createEmptyReport,
    createCheckLists,
    addAnAnswer,
    createEmptyTask,
  };
};
