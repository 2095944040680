import { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row, Stack } from "react-bootstrap";
import styled from "styled-components";

import MyNumberInput from "common/components/atoms/MyNumberInput";
import MySwitch from "common/components/atoms/MySwitch";
import MyTextbox from "common/components/atoms/MyTextbox";
import { NameIDList } from "common/components/molecules/NameIDList";

import { CustomData } from "common/types/CustomInput";
import { NameID } from "common/types/NameID";
import { InquiryData } from "../types/InquiryInfo";
import { InquiryMeta } from "../types/InquiryMeta";
import { InquiryTableDefs } from "../types/InquiryTableDef";
import DraggableModal from "common/components/molecules/DraggableModal";
import { ModalButtons } from "common/components/molecules/ModalButtons";

export type TargetInquiry = {
  index: number; // 0以下なら新規
  data: InquiryData;
};

type Props = {
  target: TargetInquiry;
  metaData: InquiryMeta;
  onOK: (data: InquiryData) => void;
  onCancel: () => void;
};

const CLEAR: string = "clear";

//=====================================
// 問合せ苦情要望編集ダイアログ
//=====================================
export const EditInquiryDlg = (props: Props) => {
  const { target, metaData, onOK, onCancel } = props;
  const nodeRef = useRef(null);
  const [item, setItem] = useState<InquiryData | null>(null);
  const [currentDetails, setCurrentDetails] = useState<NameID[] | undefined>();

  useEffect(() => {
    const itemToSet = { ...target.data };
    if (itemToSet.category) {
      // 選択されている要求種別にあわせて要求詳細を設定
      const cat = metaData.categories.find((x) => x.category.id === itemToSet.category?.id);
      setCurrentDetails(cat?.subCategories);
    }

    if (!itemToSet.customData || itemToSet.customData.length === 0) {
      itemToSet.customData = metaData.customInputs?.customs.map(
        (x) => ({ target: { id: x.id, name: x.title } } as CustomData)
      );
    }

    setItem(itemToSet);
  }, [target, metaData]);

  const isColumnVisible = (name: string): boolean => {
    if (!metaData.customInputs) return true;
    return metaData.customInputs.changes.find((x) => x.title === name)?.visible ?? true;
  };

  const columnTitle = (name: string): string => {
    if (!metaData.customInputs) return name;
    return metaData.customInputs.changes.find((x) => x.title === name)?.rename ?? name;
  };

  if (!item) return <div>不正な状態</div>;

  return (
    <Modal show={target !== null} size="lg" ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title>
          {target && target.index < 0 ? "問合せ苦情要望の追加" : "問合せ苦情要望の編集"}
        </Modal.Title>
      </Modal.Header>
      <SModalBody>
        <Stack className="gap-3">
          <FloatingLabel controlId="floatingInput" label="整理番号" hidden={true}>
            <Form.Control
              type="text"
              placeholder="ex. p_0001"
              value={item.inquiryNo}
              onChange={(e) => {
                setItem({ ...item!, inquiryNo: e.target.value });
              }}
            />
          </FloatingLabel>

          {isColumnVisible(InquiryTableDefs.Category) && (
            <>
              <Form.Group as={Row}>
                <Form.Label column md={2}>
                  {columnTitle(InquiryTableDefs.Category)} *
                </Form.Label>
                <Col sm={9} md={9} lg={6}>
                  <Form.Select
                    aria-label="要求種別"
                    value={`${item.category?.id}`}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.currentTarget.value;
                      // 選択解除
                      if (val === CLEAR) {
                        setItem({ ...item, category: undefined });
                        return;
                      }

                      // 選択
                      const cat = metaData.categories.find((x) => x.category.id.toString() === val);
                      setItem({ ...item, category: cat!.category!, subCategory: undefined });
                      setCurrentDetails(cat?.subCategories);
                    }}
                  >
                    <option value={CLEAR}>要求種別を選択</option>
                    {metaData.categories.map((cat) => {
                      return (
                        <option key={`cat_${cat.category.id}`} value={cat.category.id}>
                          {cat.category.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column md={2}>
                  {`要求詳細 ${(currentDetails?.length ?? 0) > 0 ? "*" : ""}`}
                </Form.Label>
                <Col sm={9} md={9} lg={6}>
                  <Form.Select
                    disabled={currentDetails === undefined || currentDetails.length === 0}
                    aria-label="要求詳細"
                    value={`${item.subCategory?.id}`}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = e.currentTarget.value;
                      // 選択解除
                      if (val === CLEAR) {
                        setItem({ ...item, subCategory: undefined });
                        return;
                      }
                      const cat = metaData.categories.find(
                        (x) => x.category.id === item.category?.id
                      );
                      const subCat = cat!.subCategories?.find((x) => x.id.toString() === val);
                      setItem({ ...item, subCategory: subCat });
                    }}
                  >
                    {currentDetails && currentDetails.length > 0 ? (
                      <>
                        <option value={CLEAR}>詳細種別を選択</option>
                        {currentDetails.map((sub) => (
                          <option key={`sub_${sub.id}`} value={`${sub.id}`}>
                            {sub.name}
                          </option>
                        ))}
                        ;
                      </>
                    ) : (
                      <option value={CLEAR}>詳細はありません</option>
                    )}
                  </Form.Select>
                </Col>
              </Form.Group>
            </>
          )}
          {isColumnVisible(InquiryTableDefs.Method) && (
            <Form.Group as={Row}>
              <Form.Label column md={2}>
                {/* 受付種別 */}
                {columnTitle(InquiryTableDefs.Method)} *
              </Form.Label>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  aria-label="受付者"
                  value={item.method?.id}
                  onChange={(e) => {
                    e.preventDefault();
                    const method = metaData.methods.find(
                      (x) => x.id.toString() === e.currentTarget.value
                    );
                    if (!method) {
                      setItem({ ...item, method: undefined });
                      return;
                    }

                    setItem({ ...item, method: method! });
                  }}
                >
                  <option value={CLEAR}>受付種別を選択</option>
                  {metaData.methods.map((method) => (
                    <option key={`emp_${method.id}`} value={method.id}>
                      {method.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          )}

          {isColumnVisible(InquiryTableDefs.Recipient) && (
            <Form.Group as={Row}>
              <Form.Label column md={2}>
                {/* 受付者 */}
                {columnTitle(InquiryTableDefs.Recipient)}
              </Form.Label>
              <Col sm={9} md={9} lg={6}>
                <Form.Select
                  aria-label="受付者"
                  value={item.recipient?.id}
                  onChange={(e) => {
                    e.preventDefault();
                    const recipient = metaData.employees.find(
                      (x) => x.id.toString() === e.currentTarget.value
                    );
                    if (!recipient) {
                      setItem({ ...item, recipient: undefined });
                      return;
                    }
                    setItem({ ...item, recipient: recipient! });
                  }}
                >
                  <option value={CLEAR}>受付者を選択</option>
                  {metaData.employees.map((employee) => (
                    <option key={`emp_${employee.id}`} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          )}

          {/* カスタム入力 */}
          {item &&
            metaData.customInputs?.customs.map((custom) => {
              let found = item.customData?.find((x) => x.target.id === custom.id);
              if (!found) {
                found = { target: { id: custom.id, name: custom.title } };
                item.customData?.push(found);
              }
              const key = `custom_key_${custom.id}`;
              switch (custom.type) {
                case "text": // カスタムテキスト
                  return (
                    <Form.Group as={Row}>
                      <Form.Label column md={2}>
                        {custom.title}
                      </Form.Label>
                      <Col sm={10} md={10} lg={10}>
                        <MyTextbox
                          initialValue={found.text ?? ""}
                          changed={(value) => {
                            if (!found) return;
                            found.text = value;
                            setItem({ ...item });
                          }}
                          readonly={false}
                        />
                      </Col>
                    </Form.Group>
                  );
                case "check":
                  return (
                    <Form.Group as={Row} style={{ alignItems: "baseline" }}>
                      <Form.Label column md={2}>
                        {custom.title}
                      </Form.Label>
                      <Col>
                        <MySwitch
                          initialValue={found.checked ?? false}
                          changed={(value) => {
                            if (!found) return;
                            found.checked = value;
                            setItem({ ...item });
                          }}
                        />
                      </Col>
                    </Form.Group>
                  );
                case "choice": // カスタム選択
                  return (
                    <Form.Group as={Row}>
                      <Form.Label column md={2}>
                        {custom.title}
                      </Form.Label>
                      <Col sm={6}>
                        <Form.Select
                          aria-label={custom.title}
                          value={found.selected?.id ?? -1}
                          onChange={(e) => {
                            e.preventDefault();
                            if (!found) return;
                            const selected = custom.choices?.find(
                              (x) => x.id.toString() === e.currentTarget.value
                            );
                            if (selected) {
                              found.selected = { id: selected?.id, name: selected?.title };
                            } else {
                              found.selected = undefined;
                            }
                            setItem({ ...item });
                          }}
                        >
                          <option value={CLEAR}>選択無し</option>
                          {custom.choices?.map((choice) => (
                            <option key={`custom_${custom.id}_${choice.id}`} value={choice.id}>
                              {choice.title}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  );
                default:
                  return <span key={key}></span>;
              }
            })}

          {isColumnVisible(InquiryTableDefs.Contents) && (
            <Form.Group as={Row}>
              <Form.Label column md={2}>
                {columnTitle(InquiryTableDefs.Contents)}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  value={item?.contents}
                  onChange={(e) => {
                    setItem({ ...item, contents: e.target.value });
                  }}
                  style={{ height: "100px" }}
                />
              </Col>
            </Form.Group>
          )}

          {isColumnVisible(InquiryTableDefs.Action) && (
            <Form.Group as={Row}>
              <Form.Label column md={2}>
                {columnTitle(InquiryTableDefs.Action)}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  value={item?.action}
                  onChange={(e) => {
                    setItem({ ...item, action: e.target.value });
                  }}
                  style={{ height: "100px" }}
                />
              </Col>
            </Form.Group>
          )}

          <SRowDiv>
            {isColumnVisible(InquiryTableDefs.NumIncident) && (
              <>
                <Col sm={2}>
                  <SFormLabel>
                    {/* 件数 */}
                    {columnTitle(InquiryTableDefs.NumIncident)}
                  </SFormLabel>
                </Col>
                <Col sm={3}>
                  <MyNumberInput
                    initialValue={item.numIncident}
                    changed={(value) => {
                      setItem({ ...item, numIncident: value });
                    }}
                    style={{ marginLeft: "-10px" }}
                  />
                </Col>
              </>
            )}

            {isColumnVisible(InquiryTableDefs.Completed) && (
              <SRowDiv>
                <SFormLabel>
                  {/* 完了 */}
                  {columnTitle(InquiryTableDefs.Completed)}
                </SFormLabel>
                <MySwitch
                  initialValue={item.completed}
                  changed={(changed) => {
                    setItem({ ...item, completed: changed });
                  }}
                />
              </SRowDiv>
            )}

            {isColumnVisible(InquiryTableDefs.Reported) && (
              <SRowDiv>
                <SFormLabel>
                  {/* 報告 */}
                  {columnTitle(InquiryTableDefs.Reported)}
                </SFormLabel>
                <MySwitch
                  initialValue={item.reported}
                  changed={(changed) => {
                    setItem({ ...item, reported: changed });
                  }}
                />
              </SRowDiv>
            )}
          </SRowDiv>

          {isColumnVisible(InquiryTableDefs.Areas) && (
            <Form.Group as={Row}>
              <SFormLabel column md={2}>
                {/* 地区 */}
                {columnTitle(InquiryTableDefs.Areas)}
              </SFormLabel>
              <Col sm={9} md={9} lg={6}>
                {metaData.areas.length > 0 ? (
                  <NameIDList
                    title="地区"
                    lists={metaData.areas}
                    selecteds={item.areas ?? []}
                    stringWhenEmpty="選択なし"
                    maxHeight="200px"
                    selectedChanged={(vals) => (item.areas = vals)}
                  />
                ) : (
                  <div className="h6">地区の定義がありません</div>
                )}
              </Col>
            </Form.Group>
          )}
        </Stack>
      </SModalBody>
      <Modal.Footer>
        <ModalButtons
          okDisabled={
            !item.category ||
            !item.method ||
            (currentDetails && currentDetails.length > 0 && !item.subCategory)
          }
          onOk={() => {
            onOK(item!);
          }}
          okText="決定"
          onCancel={onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

const SModalBody = styled(Modal.Body)`
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SFormLabel = styled(Form.Label)`
  text-align: left;
`;

const SRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1rem;
`;
