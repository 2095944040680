import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DataEditTarget } from "../../../common/types/DataEditTarget";
import { ExclusiveUsesMeta } from "../types/ExclusiveUsesMeta";
import { ExclusiveUsesData } from "../types/ExclusiveUsesReport";
import { Col, Form, Modal, Row, Stack } from "react-bootstrap";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import MySwitch from "common/components/atoms/MySwitch";
import { STEP_YEN } from "common/types/consts/Defines";
import DraggableModal from "common/components/molecules/DraggableModal";
import { ModalButtons } from "common/components/molecules/ModalButtons";

type Props = {
  target: DataEditTarget<ExclusiveUsesData> | null;
  metaData: ExclusiveUsesMeta;
  onOK: (data: ExclusiveUsesData) => void;
  onCancel: () => void;
};

const CLEAR: string = "clear";

export const EditExclusiveUsesDlg = (props: Props) => {
  const { target, metaData, onOK, onCancel } = props;
  const nodeRef = useRef(null);
  const [item, setItem] = useState<ExclusiveUsesData | null>(null);

  useEffect(() => {
    setItem(target?.data ?? null);
  }, [target, metaData]);

  if (!item) return <div>不正な状態</div>;

  return (
    <Modal show={target !== null} size="lg" ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title>
          {target && target.index < 0 ? "一時占用の追加" : "一時占用の編集"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack className="gap-3">
          <Form.Group as={Row}>
            <Form.Label column md={2}>
              占用種別 *
            </Form.Label>
            <Col sm={9} md={9} lg={6}>
              <Form.Select
                aria-label="占用種別"
                value={`${item.usageType?.id}`}
                onChange={(e) => {
                  e.preventDefault();
                  const val = e.currentTarget.value;
                  // 選択解除
                  if (val === CLEAR) {
                    setItem({ ...item, usageType: undefined });
                    return;
                  }
                  // 選択
                  const usageType = metaData.usageTypes.find((x) => x.id.toString() === val);
                  setItem({ ...item, usageType });
                }}
              >
                <option value={CLEAR}>占用種別を選択</option>
                {metaData.usageTypes.map((usage) => {
                  return (
                    <option key={`cat_${usage.id}`} value={usage.id}>
                      {usage.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column md={2}>
              占用者名
            </Form.Label>
            <Col md={6}>
              <Form.Control
                type="text"
                value={item.occupantName}
                onChange={(e) => {
                  e.preventDefault();
                  setItem({ ...item, occupantName: e.currentTarget.value });
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column md={2}>
              内容
            </Form.Label>
            <Col md={10} sm={12}>
              <Form.Control
                as="textarea"
                value={item.contents}
                onChange={(e) => {
                  setItem({ ...item, contents: e.target.value });
                }}
                style={{ height: "100px" }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column md={2}>
              連絡先
            </Form.Label>
            <Col md={10} sm={12}>
              <Form.Control
                type="text"
                value={item.contact}
                onChange={(e) => {
                  setItem({ ...item, contact: e.target.value });
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="d-flex align-items-center">
            <SFormLabel column sm={2}>
              料金
            </SFormLabel>
            <Col sm={3}>
              <MyNumberInput
                initialValue={item.sales}
                step={STEP_YEN}
                changed={(value) => {
                  setItem({ ...item, sales: value });
                }}
              />
            </Col>

            <SFormLabel column sm={1}>
              免除
            </SFormLabel>
            <Col sm={1}>
              <MySwitch
                initialValue={item.exemption}
                changed={(val) => {
                  setItem({ ...item, exemption: val });
                }}
              />
            </Col>
          </Form.Group>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <ModalButtons
          okDisabled={item.usageType === undefined}
          onOk={() => {
            onOK(item!);
          }}
          okText="決定"
          onCancel={onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

const SFormLabel = styled(Form.Label)`
  text-align: left;
`;
