import { memo, useContext } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";

import { getDateString } from "utils/commonTools";
import { HolidayBadge } from "../atoms/HolidayBadge";
import StatusContext from "../../store/StatusContext";
import styled from "styled-components";

type Props = {
  date: Date;
  showDaySelectionRequested?: () => void;
  prevDayRequested?: () => void;
  nextDayRequested?: () => void;
  todayRequested?: () => void;
};

export const ReportDateDisplay = memo((props: Props) => {
  const { date, showDaySelectionRequested, prevDayRequested, nextDayRequested, todayRequested } =
    props;
  const { isEditing } = useContext(StatusContext);

  return (
    <Sdiv>
      <Form.Label className="h3 pt-1">{getDateString(date)}</Form.Label>
      <Stack direction="horizontal" className="gap-1">
        <Button
          variant="outline-info"
          size="sm"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            showDaySelectionRequested?.();
          }}
          disabled={isEditing}
        >
          <i className="fas fa-calendar-days" />
        </Button>
        <Button
          variant="outline-info"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            prevDayRequested?.();
          }}
          disabled={isEditing}
        >
          前日
        </Button>

        <Button
          variant="outline-info"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            nextDayRequested?.();
          }}
          disabled={isEditing}
        >
          翌日
        </Button>

        <Button
          variant="outline-success"
          className="ms-2"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            todayRequested?.();
          }}
          disabled={isEditing}
        >
          今日
        </Button>
        <HolidayBadge date={date} />
      </Stack>
    </Sdiv>
  );
});

const Sdiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
