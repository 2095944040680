import { memo, useRef } from "react";
import { Modal } from "react-bootstrap";

import DraggableModal from "./DraggableModal";
import { ModalButtons } from "./ModalButtons";

type Props = {
  show: boolean;
  title: string;
  messages: string[] | undefined;
  onOk: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
};

const ConfirmationDlg = memo((props: Props) => {
  const { show, title, messages, onOk, onCancel, okText, cancelText } = props;
  const nodeRef = useRef();
  return (
    <Modal show={show} variant="info" ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {messages &&
          messages.map((msg, index) => (
            <div key={`msg_${index}`}>
              <span>{msg}</span>
              <br />
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <ModalButtons
          onOk={onOk}
          onCancel={onCancel}
          okText={okText || "OK"}
          cancelText={cancelText}
        />
      </Modal.Footer>
    </Modal>
  );
});

export default ConfirmationDlg;
