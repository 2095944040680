import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

import { FileListCards } from "./FileListCards";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import DraggableModal from "common/components/molecules/DraggableModal";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";
import { FILE_ACCEPTS_IMAGES } from "common/types/consts/Defines";
import { ModalButtons } from "common/components/molecules/ModalButtons";

type Props = {
  title: string;
  selecteds: RegisteredFileInfo[];
  readonly: boolean;
  onOK: (value: RegisteredFileInfo[] | null) => void;
  onCancel: () => void;
};

export const FileListModal = forwardRef<ForceCloseHandler, Props>((props: Props, ref) => {
  const { title, selecteds, readonly } = props;

  const nodeRef = useRef();
  const [pictures, setPictures] = useState<RegisteredFileInfo[] | null>(null);

  useImperativeHandle(ref, () => ({
    forceClose() {},
  }));

  useEffect(() => {
    setPictures(
      selecteds.map((x) => {
        return { ...x };
      })
    );
  }, [selecteds]);

  return (
    <SModal show={true} ref={nodeRef} dialogAs={DraggableModal} dialogClassName="myDialog">
      <Modal.Header className="handle">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SContent>
          <FileListCards
            files={pictures}
            attr={{
              title,
              accepts: FILE_ACCEPTS_IMAGES,
              usePicture: true,
              imgHeight: "10rem",
            }}
            readonly={readonly}
            isInModal={true}
            listChanged={(lists) => {
              setPictures(lists);
            }}
          />
        </SContent>
      </Modal.Body>
      <Modal.Footer>
        <ModalButtons
          onOk={() => {
            props.onOK(pictures);
          }}
          okText="決定"
          onCancel={props.onCancel}
        />
      </Modal.Footer>
    </SModal>
  );
});

const SModal = styled(Modal)`
  .modal-content {
  }
  .myDialog {
    max-width: 600px;
  }
`;

const SContent = styled.div`
  max-height: 450px;
  overflow: auto;
`;
