export const theme = {
  color: {
    title: "hsl(205, 68%, 39%)",
    menuHeader: "hsl(200, 43%, 57%)",
    menuHeaderText: "hsl(200, 43%, 100%)",
    menuSelectedBg: "hsl(104, 53%, 82%)",
    menuSelectedFg: "hsl(104, 53%, 22%)",
    primaryButton: "hsl(216,60%,52%)",
    primaryButtonHover: "hsl(216,80%,80%)",
    functionButton: "hsl(104, 33%, 62%)",
    functionButtonBorder: "hsl(104, 63%, 32%)",
    functionButtonDisabled: "#8a8d8a;",
    functionButtonHover: "hsl(104, 62%, 62%)",
    editButton: "hsl(216,60%,52%)",
    editButtonBorder: "#444",
    editButtonHover: "hsl(216,80%,80%)",
    deleteButton: "hsl(45, 80%, 53%)",
    deleteButtonBorder: "hsl(45, 80%, 33%)",
    deleteButtonHover: "hsl(45, 100%, 53%)",
    disabled: "hsl(205, 10%, 39%)",
    printButton: "hsl(334, 90%, 60%)",
    printButtonBorder: "hsl(334, 70%, 50%)",
    printButtonHover: "hsl(334, 90%, 80%)",
  },
};
