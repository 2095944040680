import { useContext } from "react";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import { useFiles } from "./useFiles";
import StatusContext from "common/store/StatusContext";

export const useFileUtils = () => {
  const { uploadFile, deleteFile } = useFiles();
  const { setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const processPictures = async (parkId: number, pictures: RegisteredFileInfo[]) => {
    await Promise.all(
      pictures.map(async (theFile) => {
        if (theFile.deleted && theFile.fileId >= 0) {
          // ファイルの削除
          // fileId があるということはサーバーのデータも消さなければいけない
          await execFileDelete(parkId, theFile);
        } else if (theFile.file) {
          // ファイルの追加
          // fileに何か入っている＝ローカルの写真を読み込んでメモリに持っている状態
          await execFileUpload(parkId, theFile);
        }
      })
    );
  };

  //--------------------------------------
  // ファイルのアップロード処理
  //--------------------------------------
  const execFileUpload = async (parkId: number, file: RegisteredFileInfo) => {
    setLoadingMessage(`ファイル:"${file.title}(${file.fileName})"のアップロード中`);

    const { succeeded, msg, fileId } = await uploadFile(parkId, file.file!);
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg);
      return;
    }

    file.fileId = fileId!;
    file.file = undefined;
  };

  //--------------------------------------
  // ファイルの削除処理
  //--------------------------------------
  const execFileDelete = async (parkId: number, file: RegisteredFileInfo) => {
    setLoadingMessage(`ファイル:"${file.title}(${file.fileName})"の削除中`);

    const { succeeded, msg } = await deleteFile(parkId, file.fileId);
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage("ファイルの削除に失敗しました。" + msg!);
      return false;
    }
    return true;
  };

  return { processPictures };
};
