import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled, { CSSProperties } from "styled-components";

type Params = {
  className?: string;
  id?: string;
  initialValue: string;
  changed: (value: string) => void;
  readonly: boolean;
  borderAlways?: boolean;
  style?: CSSProperties;
};
const MyTextArea = memo((params: Params) => {
  const {
    className,
    id,
    initialValue,
    changed,
    readonly = false,
    borderAlways = true,
    style,
  } = params;
  const [text, setText] = useState("");

  useEffect(() => {
    setText(initialValue);
  }, [initialValue]);

  return (
    <SFormText
      as="textarea"
      borderAlways={borderAlways}
      className={`form-control ${className ?? ""}`}
      style={style}
      id={id}
      value={text}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setText(e.target.value);
        changed(e.target.value);
      }}
      readOnly={readonly}
    />
  );
});

export default MyTextArea;

const SFormText = styled(Form.Control)<{ borderAlways: boolean }>`
  height: 100px;
  line-height: 1.2;
  border-width: 2px;
  font-size: ${(props) => props.theme.textSizeNormal};
  :read-only {
    border-width: 1px;
    box-shadow: none;
    resize: none;
    border: ${(props) => (props.borderAlways ? "" : "none")};
    background-color: transparent;
  }
`;
