import { createRef, forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import styled from "styled-components";

import StatusContext from "common/store/StatusContext";

import { ParkEvent } from "../types/ParkEventData";
import { useEventReport } from "pages/print/hooks/useEventReport";

import { TimeInput } from "common/components/atoms/TimeInput";
import { FileListCards } from "common/components/organisms/files/FileListCards";
import { FILE_ACCEPTS_IMAGES } from "common/types/consts/Defines";

import { EventParticipantsTable } from "./EventParticipantsTable";
import { RevenueReportPanel } from "./RevenueReportPanel";
import { EVENT_TYPE } from "../types/EVENT_TYPE";
import MyTextbox from "common/components/atoms/MyTextbox";
import FunctionButton from "common/components/atoms/FunctionButton";
import MyTextArea from "common/components/atoms/MyTextArae";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";

type Props = {
  data: ParkEvent;
  eventType: EVENT_TYPE;
  dataUpdated: (data: ParkEvent) => void;
  participantsChanged?: () => void;
  revenueChanged?: () => void;

  deleteDataRequested: (data: ParkEvent) => void;
  // 事業名を表示・編集する場合はここを設定する
  editTitleRequested?: (data: ParkEvent) => void;
  saveDataRequested: () => Promise<boolean>;
  readonly: boolean;
  printRequested?: () => Promise<void>; // 独自の印刷（期間イベント）をする場合にセットする
};

//=====================================
// 公園の事業ごとの情報を入力するパネル
//=====================================
export const ParkEventPanel = forwardRef<ForceCloseHandler, Props>((props: Props, ref) => {
  const {
    data,
    eventType,
    dataUpdated,
    deleteDataRequested,
    editTitleRequested,
    readonly,
    revenueChanged,
    participantsChanged,
    printRequested,
  } = props;
  const { currentDate, setIsLoading, setLoadingMessage, setErrorMessage } =
    useContext(StatusContext);

  const [event, setEvent] = useState(data);
  const { getEventReort } = useEventReport();

  const pictureListRef = createRef<ForceCloseHandler>();

  useImperativeHandle(ref, () => ({
    forceClose() {
      pictureListRef.current?.forceClose();
    },
  }));

  //--------------------------------------
  // 文字列"hh:mm"を時刻に変換する
  //--------------------------------------
  const getTime = (timeString: string) => {
    if (timeString === "") return null;

    const hourMin = timeString.split(":").map((x) => Number.parseInt(x));
    var dt = new Date();
    dt.setHours(hourMin[0]);
    dt.setMinutes(hourMin[1]);
    return dt;
  };

  //--------------------------------------
  // 時刻を文字列"hh:mm"に変換する
  //--------------------------------------
  const toTimeString = (dt: Date | null) => {
    if (!dt) return "";
    return `${dt.getHours()}:${dt.getMinutes().toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    setEvent(data);
  }, [data]);

  const onPrintClick = async () => {
    if (printRequested) {
      await printRequested();
      return;
    }
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage(`"${event.title}"の帳票を作成中...`);
    const { succeeded, msg } = await getEventReort(
      currentDate,
      eventType,
      event.title,
      event.categoryId
    );

    setIsLoading(false);
    if (!succeeded) setErrorMessage(msg);
  };

  //--------------------------------------
  // render
  //--------------------------------------
  return (
    <Stack className="p-2" gap={1}>
      <Container className="px-0 d-flex justify-content-between align-items-center">
        {editTitleRequested ? (
          <h4
            style={{ cursor: `${readonly ? "" : "pointer"}` }}
            className="py-2 pe-auto"
            onClick={(e) => !readonly && editTitleRequested(data)}
          >{`事業名：${event.title}`}</h4>
        ) : (
          <span></span>
        )}
        <div>
          {readonly && (
            <FunctionButton
              buttonType="print"
              size="md"
              data-tip="印刷"
              tooltip="印刷"
              onClick={(e) => {
                e.preventDefault();
                onPrintClick();
              }}
            >
              <i className="fas fa-print" />
            </FunctionButton>
          )}
          {!readonly && (
            <FunctionButton
              size="sm"
              buttonType="delete"
              data-tip="削除"
              tooltip="削除"
              className="ms-2"
              onClick={(e) => {
                e.preventDefault();
                deleteDataRequested(data);
              }}
            >
              <i className="fas fa-trash-alt" />
            </FunctionButton>
          )}
        </div>
      </Container>
      <STimeDiv>
        <SLabelTime htmlFor="timsStart">開始時間</SLabelTime>
        <TimeInput
          id="timeStart"
          InitialValue={getTime(event.startTime)}
          readonly={readonly}
          TimeChanged={(dt) => {
            event.startTime = toTimeString(dt);
          }}
        />
        <SLabelTime htmlFor="timeEnd" className="ps-2">
          終了時間
        </SLabelTime>
        <TimeInput
          id="timeEnd"
          InitialValue={getTime(event.endTime)}
          readonly={readonly}
          TimeChanged={(dt) => {
            event.endTime = toTimeString(dt);
          }}
        />
      </STimeDiv>
      <Row>
        <Form.Label htmlFor="txtLocation" className="mt-2 mb-1">
          開催場所
        </Form.Label>
        <Col md={6}>
          <MyTextbox
            id="textLocation"
            initialValue={event.location}
            changed={(txt) => (event.location = txt)}
            readonly={readonly}
            borderAlways={true}
          />
        </Col>
      </Row>
      <SLabel htmlFor="txtDescription">概要</SLabel>
      <MyTextArea
        id="txtDescription"
        readonly={readonly}
        initialValue={event.description}
        changed={(value) => {
          var newData = { ...event, description: value };
          setEvent(newData);
          dataUpdated(newData);
        }}
      />

      <SLabel htmlFor="txtReport">報告内容</SLabel>
      <MyTextArea
        id="txtReport"
        readonly={readonly}
        initialValue={event.report}
        changed={(value) => {
          var newData = { ...event, report: value };
          setEvent(newData);
          dataUpdated(newData);
        }}
      />
      <SCustomRow>
        <div style={{ width: 300 }}>
          <SLabel>参加人数</SLabel>
          {event.participants.length > 0 ? (
            <EventParticipantsTable
              participants={event.participants}
              readonly={readonly}
              updated={participantsChanged}
            />
          ) : (
            <div className="pt-1 pb-3">人数種別の定義がありません</div>
          )}
        </div>
        {eventType !== EVENT_TYPE.Collaborate && event.revenueReport && (
          <div style={{ width: 300 }}>
            <SLabel>収支報告</SLabel>
            <RevenueReportPanel
              revenue={event.revenueReport}
              readonly={readonly}
              updated={revenueChanged}
            />
          </div>
        )}
      </SCustomRow>
      <SLabel htmlFor="txtNote">特記事項</SLabel>
      <MyTextArea
        className="form-control"
        id="txtNote"
        readonly={readonly}
        initialValue={event.note}
        changed={(value) => {
          var newData = { ...event, note: value };
          setEvent(newData);
          dataUpdated(newData);
        }}
      />
      <div className="mb-3"></div>
      <FileListCards
        files={event.pictures}
        attr={{
          title: "写真",
          accepts: FILE_ACCEPTS_IMAGES,
          usePicture: true,
          hasComment: true,
          imgHeight: "8rem",
        }}
        ref={pictureListRef}
        readonly={readonly}
        listChanged={(files) => {
          const newData = { ...event, pictures: files ?? [] };
          setEvent(newData);
          dataUpdated(newData);
        }}
      />
    </Stack>
  );
});

const STimeDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const SLabelTime = styled(Form.Label)`
  margin-bottom: 0;
`;

const SLabel = styled(Form.Label)`
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
`;

const SCustomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
`;
