export enum PRINT_TYPE {
  PARK_DAILY = 100,
  WORK_REPORT = 101,
  PRIVATE_BIZ = 102,
  COOP_BIZ = 103,
  MONTHLY = 104,
  AGGREGATE = 105,
  INQUIRIES = 106,
  PERIOD_EVENT = 111,
}
