import { memo, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

import { ReportDateDisplay } from "../molecules/ReportDataDisplay";
import LastUpdatedCtrl from "../molecules/LastUpdatedCtrl";

import { ILCalendarDlg } from "./ILCalendarDlg";
import { addDays } from "utils/DateTools";
import { genEditKey } from "utils/commonTools";
import { EditControl } from "./EditControl";

type Props = {
  title: string;
  reportType: REPORT_TYPE;
  tabId: TAB_ID;
  lastUpdated?: LastUpdatedInfo;
  showLastupdated?: boolean;
  onEditStart: () => Promise<void>;
  onSave?: () => Promise<void>;
  onCancel?: () => Promise<void>;
};

//=====================================
// 各ページの上に表示する項目
// 更新日、日付や公園名など
//=====================================
export const PageTop = memo((props: Props) => {
  const {
    title,
    reportType,
    tabId,
    lastUpdated,
    showLastupdated = true,
    onEditStart,
    onSave,
    onCancel,
  } = props;
  const { selectedPark } = useContext(AuthContext);
  const { currentDate, selectDate } = useContext(StatusContext);

  const [showCalendar, setShowCalendar] = useState(false);

  const toPrevDay = () => {
    const newDate = addDays(currentDate, -1);
    selectDate(newDate);
  };

  const toNextDay = () => {
    const newDate = addDays(currentDate, 1);
    selectDate(newDate);
  };

  const toToday = () => {
    selectDate(new Date());
  };

  const dateSelected = (date: Date) => {
    selectDate(date);
    setShowCalendar(false);
  };

  if (!selectedPark) return <div>公園が選択されていません</div>;

  return (
    <>
      <SForm className="sticky-top">
        <div className="d-flex justify-content-between align-items-center ms-2 mt-0">
          <ReportDateDisplay
            date={currentDate}
            showDaySelectionRequested={() => setShowCalendar(true)}
            prevDayRequested={toPrevDay}
            nextDayRequested={toNextDay}
            todayRequested={toToday}
          />
          {onSave && onCancel && (
            <EditControl
              parkId={selectedPark.parkId}
              editKey={genEditKey(currentDate)}
              reportType={reportType}
              tabId={tabId}
              onEditStart={onEditStart}
              onSave={onSave}
              onCancel={onCancel}
            />
          )}
        </div>
        <div className="d-flex px-2 mt-1" style={{ flexWrap: "wrap" }}>
          {/* ページタイトル */}
          <span className="h4">{title}</span>
          {showLastupdated ? (
            <LastUpdatedCtrl lastUpdated={lastUpdated} className="ms-auto mt-auto" />
          ) : (
            <div className="mt-3" />
          )}
        </div>
      </SForm>
      {showCalendar && (
        <ILCalendarDlg
          show={showCalendar}
          initialValue={currentDate}
          onOK={dateSelected}
          onCancel={() => setShowCalendar(false)}
        />
      )}
    </>
  );
});

const SForm = styled(Form)`
  background-color: #ffffff;
  margin-top: 5px;
  padding: 0.5rem 0rem;
`;
