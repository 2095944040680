import { useState, useContext, useEffect } from "react";
import { Row, Col, Stack } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { StaffAttendanceReport } from "pages/parkstaffs/types/StaffInfo";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import { useStaffs } from "pages/parkstaffs/hooks/useStaffs";
import { createDocumentTitle } from "utils/commonTools";

import MyTextbox from "common/components/atoms/MyTextbox";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import { PageTop } from "common/components/organisms/PageTop";
import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import { BaseForm } from "common/components/templates/BaseForm";

import { ProperStaffsTable } from "./components/ProperStaffsTable";
import { TempStaffsTable } from "./components/TempStaffsTable";

import { useStaffsData } from "./hooks/useStaffsData";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

let isInitializing = false;

type Props = {
  pageTitle: string;
  reportType: REPORT_TYPE;
};

//=====================================
// 勤務者数ページ
//=====================================
function ParkStaffsPage(props: Props) {
  const { pageTitle, reportType } = props;
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);
  const { loadMetaData, getReportData, saveReportData } = useStaffs();
  const { clearData, applyData } = useStaffsData();

  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);

  const { selectedPark, readOnly } = useContext(AuthContext);

  const [reportData, setReportData] = useState<StaffAttendanceReport | null>(null);

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<StaffAttendanceReport | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);
    setIsLoading(false);
    if (succeeded) {
      return data!;
    } else {
      setErrorMessage(msg!);
      return null;
    }
  };

  //--------------------------------------
  // 日報データをサーバーから取得する
  //--------------------------------------
  const loadReportData = async (baseData: StaffAttendanceReport) => {
    if (!selectedPark) return;

    // フィールドは一旦クリア。登録済み日報が存在すれば後で更新される
    const newdata = clearData(baseData);
    if (!newdata) {
      setErrorMessage("フィールドのクリアに失敗しました");
      return;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      reportType,
      currentDate
    );
    setIsLoading(false);

    if (!succeeded) {
      setErrorMessage(msg);
      return;
    }

    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );

    if (!lastUpdated) {
      // ”新規作成”で日報、作業日報のどちらかで保存済みの場合、コピーする
      const { succeeded, data, lastUpdated } = await getReportData(
        selectedPark.parkId,
        reportType === REPORT_TYPE.DAILY ? REPORT_TYPE.WORK : REPORT_TYPE.DAILY,
        currentDate
      );
      if (succeeded && lastUpdated) {
        setReportData(applyData(data!, newdata));
        showToastMessage(
          "アシスタント",
          `${reportType === REPORT_TYPE.DAILY ? "作業日報" : "公園日報"}をコピーしました`
        );
        return;
      }
    }
    setReportData(applyData(data, newdata));
  };

  //--------------------------------------
  // 更新処理の実行
  //--------------------------------------
  const onSave = async () => {
    if (!selectedPark || !reportData) return;

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      reportType,
      currentDate,
      reportData,
      pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }

      showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = reportData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData(baseData);
    };
    f();
  }, [currentDate, reportType]); // eslint-disable-line react-hooks/exhaustive-deps

  //--------------------------------------
  // Rendering
  //--------------------------------------
  return (
    <DashboardTemplate>
      {!selectedPark && <div> 公園が選択されていません </div>}
      {selectedPark && reportData && (
        <>
          <PageTop
            title={pageTitle}
            reportType={reportType}
            tabId={TAB_ID.STAFFS}
            lastUpdated={lastUpdated}
            onEditStart={async () => await loadReportData(reportData)}
            onSave={!readOnly ? onSave : undefined}
            onCancel={async () => await loadReportData(reportData)}
          />
          <BaseForm>
            <Row className="">
              {reportData.fulltime && (
                <Col xl={6}>
                  <ProperStaffsTable
                    data={reportData.fulltime}
                    dataChanged={(val) => {
                      setReportData({ ...reportData, fulltime: val });
                    }}
                    readOnly={!isEditing}
                  />
                </Col>
              )}
              {reportData.parttime && (
                <Col xl={6}>
                  <TempStaffsTable
                    data={reportData.parttime}
                    dataChanged={(val) => {
                      setReportData({ ...reportData, parttime: val });
                    }}
                    readOnly={!isEditing}
                    title="非常勤・スタッフ 出勤"
                  />
                </Col>
              )}
              {reportData.tempstaff && (
                <Col xl={6}>
                  <TempStaffsTable
                    data={reportData.tempstaff}
                    dataChanged={(val) => {
                      setReportData({ ...reportData, tempstaff: val });
                    }}
                    readOnly={!isEditing}
                    title="臨時雇用(常用)"
                  />
                </Col>
              )}
            </Row>
            <Col className="py-2" lg={6} md={12}>
              <h4>その他勤務者</h4>
              <Stack className="px-0 pb-4" style={{ gap: "4px" }}>
                <label>名前</label>
                <MyTextbox
                  initialValue={reportData.others}
                  changed={(txt) => (reportData.others = txt)}
                  readonly={!isEditing}
                  borderAlways={true}
                  className="w-100"
                  style={{ fontSize: "14px" }}
                />
                <label>人数</label>
                <Col sm={4} className="d-flex align-items-center">
                  <MyNumberInput
                    initialValue={reportData.numothers}
                    changed={(number) => (reportData.numothers = number)}
                    readonly={!isEditing}
                    borderAlways={true}
                  />
                  <span className="mx-2">人</span>
                </Col>
              </Stack>
            </Col>
          </BaseForm>
        </>
      )}
    </DashboardTemplate>
  );
}

export default ParkStaffsPage;
