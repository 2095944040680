import { useContext, useState } from "react";

import StatusContext from "common/store/StatusContext";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import { useDailyReport } from "../hooks/useDailyReport";
import { PrintPanelTemplate } from "./PrintPanelTemplate";
import { SelectDayControl } from "./molecules/SelectDayControl";

export const PrintDailyControl = (props: { title: string; printType: number }) => {
  const [date, setDate] = useState(new Date());

  const { getDailyReort } = useDailyReport();
  const { isLoading, setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const getReport = async () => {
    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage(`帳票"${props.title}"を作成中...`);
    const { succeeded, msg } = await getDailyReort(date, props.printType, props.title);
    setIsLoading(false);

    if (!succeeded) {
      setErrorMessage(msg);
    }
  };

  return (
    <PrintPanelTemplate>
      <SelectDayControl date={date} dateChanged={setDate} />
      <PrimaryButton title="出力" size="sm" onClick={getReport} disabled={isLoading} />
    </PrintPanelTemplate>
  );
};
