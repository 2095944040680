import { useState } from "react";
import { Form, Table } from "react-bootstrap";
import styled from "styled-components";

import MyCheckbox from "common/components/atoms/MyCheckbox";
import MyTextbox from "common/components/atoms/MyTextbox";

import { InActiveStyle } from "common/types/styles/InActiveStyle";
import { StaffAttendance } from "../types/StaffInfo";

type Props = {
  title: string;
  data: Array<StaffAttendance>;
  dataChanged: (newVal: StaffAttendance[]) => void;
  readOnly?: boolean;
};

export const TempStaffsTable = (props: Props) => {
  const { data, dataChanged, readOnly = false, title } = props;

  const [planChecked, setPlanChecked] = useState(false);
  const [actualChecked, setActualChecked] = useState(false);

  const onPlanChanged = () => {
    setPlanChecked(data.every((x) => x.expected));
  };

  const onActualChanged = () => {
    setActualChecked(data.every((x) => x.attended));
  };

  const onPlanCheckChanged = (value: boolean) => {
    data.forEach((x) => (x.expected = value));
    setPlanChecked(value);
  };

  const onActualCheckChanged = (value: boolean) => {
    data.forEach((x) => (x.attended = value));
    setActualChecked(value);
  };

  return (
    <>
      <h4>{title}</h4>
      {!data ? (
        <p>データがありません</p>
      ) : (
        <Table bordered hover responsive>
          <thead>
            <tr className="text-center">
              <th className="col-md-*">名前</th>
              <th className="col-md-*">
                <Scheck>
                  {!readOnly && (
                    <Form.Check
                      checked={planChecked}
                      onChange={(e) => onPlanCheckChanged(e.currentTarget?.checked)}
                    />
                  )}
                  <span>予定</span>
                </Scheck>
              </th>
              <th className="col-md-*">
                <Scheck>
                  {!readOnly && (
                    <Form.Check
                      checked={actualChecked}
                      onChange={(e) => onActualCheckChanged(e.currentTarget?.checked)}
                    />
                  )}
                  <span>実績</span>
                </Scheck>
              </th>
              <th className="col-md-*">備考</th>
            </tr>
          </thead>
          <tbody>
            {data.map((person) => {
              return (
                <tr
                  key={`employee_${person.employeeID}`}
                  style={person.retired ? InActiveStyle : undefined}
                >
                  <TdLeft> {person.employeeName} </TdLeft>
                  <TdCenter>
                    <MyCheckbox
                      initialValue={person.expected}
                      changed={(checked) => {
                        person.expected = checked;
                        dataChanged(data);
                        onPlanChanged();
                      }}
                      readonly={readOnly}
                    />
                  </TdCenter>
                  <TdCenter>
                    <MyCheckbox
                      initialValue={person.attended}
                      changed={(checked) => {
                        person.attended = checked;
                        dataChanged(data);
                        onActualChanged();
                      }}
                      readonly={readOnly}
                    />
                  </TdCenter>
                  <TdCenter>
                    <MyTextbox
                      className=""
                      initialValue={person.memo}
                      changed={(text) => {
                        person.memo = text;
                        dataChanged(data);
                      }}
                      readonly={readOnly}
                    />
                  </TdCenter>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

const TdLeft = styled.td`
  vertical-align: middle;
  text-align: left;
`;

const TdCenter = styled.td`
  vertical-align: middle;
  text-align: center;
`;

const Scheck = styled.th`
  min-width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
`;
