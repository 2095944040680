import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form, Table } from "react-bootstrap";
import styled from "styled-components";

import FunctionButton from "common/components/atoms/FunctionButton";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { WorkDefinition, WorkReportMeta } from "../types/WorkReportMeta";
import { Task } from "../types/WorkReportData";
import { useWorkReportData } from "../hooks/useWorkReportData";
import { INVALID_ID } from "common/types/consts/Defines";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";
import { useAreas } from "common/hooks/useAreas";
import { MultiLineText } from "common/components/atoms/MultiLineText";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";
import { SelectTaskDlg } from "./SelectTaskDlg";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import MyTextArea from "common/components/atoms/MyTextArae";
import { NameIDList } from "common/components/molecules/NameIDList";
import MyTextbox from "common/components/atoms/MyTextbox";
import { FileListModal } from "common/components/organisms/files/FileListModal";

type Props = {
  tabName: string;
  tasks: Task[];
  meta: WorkReportMeta;
  readonly: boolean;
  tasksChanged: (tasks: Task[]) => void;
  title?: string;
};

export const TaskReportPanel = forwardRef<ForceCloseHandler, Props>((props: Props, ref) => {
  const { tabName, meta, readonly, tasksChanged, title } = props;
  const [tasks, setTasks] = useState<Task[]>(props.tasks);
  const [editTarget, setEditTarget] = useState<Task | null>(null);
  const [pictureTarget, setPictureTarget] = useState<Task | null>(null);
  const [targetIndex, setTargetIndex] = useState(INVALID_ID);
  const [deleteTarget, setDeleteTarget] = useState<Task | null>(null);
  const { createEmptyTask } = useWorkReportData();
  const { toAreaString } = useAreas();

  useImperativeHandle(ref, () => ({
    forceClose() {
      if (editTarget) setEditTarget(null);
      if (deleteTarget) setDeleteTarget(null);
      if (pictureTarget) setPictureTarget(null);
    },
  }));

  const onAddData = () => {
    const newTask = createEmptyTask();
    setEditTarget(newTask);
    setTargetIndex(INVALID_ID);
  };

  const onSelectTaskCompleted = (taskType: WorkDefinition) => {
    if (!editTarget) return;

    editTarget.taskType = { id: taskType.id, name: taskType.name };
    editTarget.unitOfTask = taskType.workUnit;
    let newTasks: Task[];
    if (targetIndex === INVALID_ID) {
      newTasks = [...tasks, editTarget];
    } else {
      newTasks = [...tasks];
      newTasks[targetIndex] = editTarget;
    }
    setTasks(newTasks);
    tasksChanged(newTasks);

    setEditTarget(null);
  };

  const onEditDataCanceled = () => {
    setEditTarget(null);
  };

  const onDeleteData = (task: Task, index: number) => {
    setDeleteTarget(task);
    setTargetIndex(index);
  };

  const confirmDeleteOK = async () => {
    const newTasks = tasks.filter((x) => x !== deleteTarget);
    setTasks(newTasks);
    tasksChanged(newTasks);
    setDeleteTarget(null);
  };

  const getActivePictures = (task: Task) => {
    return task.pictures.filter((pict) => pict.deleted === undefined);
  };

  useEffect(() => {
    setTasks(props.tasks);
  }, [props.tasks]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        <div className="h4">{title}</div>
        {!readonly && (
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              onAddData();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
        )}
      </div>

      <STable bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th className="col-md-2" style={{ minWidth: "100px" }}>
              作業種別
            </th>
            <th className="col-md-1" style={{ minWidth: "80px" }}>
              計画数量
            </th>
            <th className="col-md-1" style={{ minWidth: "80px" }}>
              作業数量
            </th>
            <th className="col-md-*" style={{ minWidth: "200px" }}>
              作業内容
            </th>
            <th className="col-md-1" style={{ minWidth: "100px" }}>
              事業者区分
            </th>
            <th className="col-md-1" style={{ minWidth: "150px" }}>
              事業者名称
            </th>
            <th className="col-md-1" style={{ minWidth: "150px" }}>
              地区
            </th>
            <th className="col-md-1" style={{ minWidth: "60px" }}>
              写真
            </th>
            {!readonly && <th style={{ width: "60px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={`task_${index}`}>
              <TdLeft>
                <SSpan
                  className={`${readonly ? "disabled" : ""}`}
                  onClick={() => {
                    if (readonly) return;
                    setEditTarget(task);
                    setTargetIndex(index);
                  }}
                >
                  {task.taskType.name}
                </SSpan>
              </TdLeft>
              <TdCenter className="text-end">
                <SNumberDiv>
                  <MyNumberInput
                    initialValue={task.plannedAmount}
                    borderAlways={false}
                    readonly={readonly}
                    style={{ fontSize: "14px", width: "100%" }}
                    changed={(value) => {
                      task.plannedAmount = value;
                      tasksChanged(tasks);
                    }}
                  />
                  {task.unitOfTask && <SUnit>{`${task.unitOfTask}`}</SUnit>}
                </SNumberDiv>
              </TdCenter>
              <TdCenter className="text-end">
                <SNumberDiv>
                  <MyNumberInput
                    initialValue={task.actualAmount}
                    borderAlways={false}
                    readonly={readonly}
                    style={{ fontSize: "12px" }}
                    changed={(value) => {
                      task.actualAmount = value;
                      tasksChanged(tasks);
                    }}
                  />
                  {task.unitOfTask && <SUnit>{task.unitOfTask}</SUnit>}
                </SNumberDiv>
              </TdCenter>
              <TdLeft>
                {readonly ? (
                  <MultiLineText text={task.taskContent} />
                ) : (
                  <SMyTextArea
                    style={{ height: "60px" }}
                    readonly={readonly}
                    initialValue={task.taskContent}
                    borderAlways={false}
                    changed={(value) => {
                      task.taskContent = value;
                      tasksChanged(tasks);
                    }}
                  />
                )}
              </TdLeft>
              <TdCenter>
                {readonly ? (
                  <span>{task.contractType.name}</span>
                ) : (
                  <Form.Select
                    aria-label="事業者区分"
                    value={task.contractType.id}
                    style={{ fontSize: "12px", backgroundImage: "10px" }}
                    onChange={(e) => {
                      e.preventDefault();
                      const val = Number(e.currentTarget.value);
                      if (val === INVALID_ID) {
                        task.contractType = { id: val, name: "" };
                      } else {
                        const contractType = meta.contractTypes.find((x) => x.id === val);
                        if (contractType) {
                          task.contractType = { id: val, name: contractType.name };
                        }
                      }
                      setTasks([...tasks]);
                      tasksChanged(tasks);
                    }}
                  >
                    <option value={INVALID_ID}>選択なし</option>
                    {meta.contractTypes.map((contractType) => {
                      return (
                        <option key={`contractType_${contractType.id}`} value={contractType.id}>
                          {contractType.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </TdCenter>
              <TdLeft>
                {readonly ? (
                  <div>{task.contractorName}</div>
                ) : (
                  <SMyTextbox
                    readonly={readonly}
                    initialValue={task.contractorName}
                    changed={(value) => {
                      task.contractorName = value;
                      tasksChanged(tasks);
                    }}
                  />
                )}
              </TdLeft>
              <TdCenter>
                {readonly ? (
                  <MultiLineText text={toAreaString(task.areas)} />
                ) : (
                  <NameIDList
                    title="地区"
                    style={{ fontSize: "14px" }}
                    lists={meta.areas}
                    selecteds={task.areas}
                    stringWhenEmpty=""
                    selectedChanged={(vals) => {
                      task.areas = vals;
                      tasksChanged(tasks);
                    }}
                  />
                )}
              </TdCenter>
              <TdCenter>
                {readonly ? (
                  <SPictureSpan onClick={() => setPictureTarget(task)}>
                    {getActivePictures(task).length > 0 && `${getActivePictures(task).length} 枚`}
                  </SPictureSpan>
                ) : (
                  <FunctionButton
                    children={
                      getActivePictures(task).length > 0
                        ? `${getActivePictures(task).length} 枚`
                        : "追加"
                    }
                    onClick={() => setPictureTarget(task)}
                  />
                )}
              </TdCenter>
              {!readonly && (
                <td className="text-center">
                  <RowEditButtons deleteRequested={() => onDeleteData(task, index)} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </STable>
      {editTarget && (
        <SelectTaskDlg
          index={targetIndex}
          initialValue={editTarget.taskType}
          workDefinitions={meta.workDefinitions}
          tabName={tabName}
          onOK={onSelectTaskCompleted}
          onCancel={onEditDataCanceled}
        />
      )}
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={[`"${deleteTarget.taskType.name}" を削除しても良いですか`]}
          onOk={confirmDeleteOK}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}
      {pictureTarget && (
        <FileListModal
          title={`"${pictureTarget.taskType.name}"の写真`}
          selecteds={pictureTarget.pictures}
          readonly={readonly}
          onOK={(values) => {
            pictureTarget.pictures = values ?? [];
            tasksChanged(tasks);
            setPictureTarget(null);
          }}
          onCancel={() => setPictureTarget(null)}
        />
      )}
    </>
  );
});

const STable = styled(Table)`
  td {
    vertical-align: middle;
  }
  span {
    padding: 0.5rem;
  }
`;

const TdLeft = styled.td`
  vertical-align: middle;
`;

const TdCenter = styled.td`
  vertical-align: middle;
  text-align: center;
`;

const SSpan = styled.span`
  cursor: pointer;
  &.disabled {
    cursor: default;
  }
`;

const SMyTextArea = styled(MyTextArea)`
  font-size: 12px;
  display: flex;
  border-width: 1px;
`;

const SMyTextbox = styled(MyTextbox)`
  font-size: 12px;
  border-width: 1px;
`;

const SNumberDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SUnit = styled.span`
  font-size: smaller;
  margin: 0;
  padding: 0 !important;
  justify-self: flex-end;
  flex-grow: 1;
`;

const SPictureSpan = styled.span`
  cursor: pointer;
`;
