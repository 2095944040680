import { useRef, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import DraggableModal from "common/components/molecules/DraggableModal";
import { ModalButtons } from "common/components/molecules/ModalButtons";

type Props = {
  show: boolean;
  title: string;
  hasComment?: boolean;
  fileInfo: RegisteredFileInfo;
  onOk: (fileTitle: string, comment?: string) => void;
  onCancel: () => void;
};

export const EditFileInfoDlg = (props: Props) => {
  const { show, title, hasComment = false, fileInfo, onOk, onCancel } = props;
  const nodeRef = useRef();

  const [fileTitle, setFileTitle] = useState(fileInfo.title);
  const [comment, setComment] = useState(fileInfo.comment);

  return (
    <Modal show={show} ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <FloatingLabel className="mb-2 my-2 " label="タイトル">
              <Form.Control
                type="text"
                placeholder="タイトルを入力してください"
                value={fileTitle}
                onChange={(e) => setFileTitle(e.target.value)}
                autoComplete="off"
              />
            </FloatingLabel>
          </Col>
        </Row>
        {hasComment && (
          <FloatingLabel className="mb-2 my-2 " label="コメント">
            <Form.Control
              as="textarea"
              placeholder="コメントを入力してください"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ height: "100px" }}
            />
          </FloatingLabel>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ModalButtons
          okDisabled={!fileTitle}
          onOk={() => {
            onOk(fileTitle, comment);
          }}
          okText="決定"
          onCancel={onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};
