import { useState, useContext, useEffect, createRef } from "react";
import { Form } from "react-bootstrap";

import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";
import { FileListCards } from "common/components/organisms/files/FileListCards";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { useFileUtils } from "common/hooks/useFileUtils";
import {
  FILE_ACCEPTS_ALL,
  FILE_ACCEPTS_IMAGES,
  REPORT_TYPE,
  TAB_ID,
} from "common/types/consts/Defines";

import { createDocumentTitle } from "utils/commonTools";

import { useSpecialNotes } from "./hooks/useSpecialNotes";
import { PageTop } from "common/components/organisms/PageTop";
import MyTextArea from "common/components/atoms/MyTextArae";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";

type Props = {
  pageTitle: string;
  reportType: REPORT_TYPE;
};

// ------------------------------------------------------------------
// 特記事項ページ
//-------------------------------------------------------------------
const SpecialNotesPage = (props: Props) => {
  const { pageTitle, reportType } = props;
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);

  const { processPictures } = useFileUtils();
  const { getReportData, saveReportData } = useSpecialNotes();

  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);
  const { selectedPark, readOnly } = useContext(AuthContext);

  const [files, setFiles] = useState<RegisteredFileInfo[] | null>([]);
  const [pictures, setPictures] = useState<RegisteredFileInfo[] | null>([]);
  const [comment, setComment] = useState<string>("");

  const fileListRef = createRef<ForceCloseHandler>();
  const pictureListRef = createRef<ForceCloseHandler>();

  //--------------------------------------
  // 日報データをサーバーから取得する
  // メタデータを取得しないため、他のタブとは異なる
  //--------------------------------------
  const loadReportData = async () => {
    setErrorMessage("");

    if (selectedPark == null) {
      setErrorMessage("公園が選択されていません");
      return;
    }

    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      reportType,
      currentDate
    );
    setIsLoading(false);
    if (succeeded) {
      setLastUpdated(
        lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
      );
      setFiles(data?.files ?? null);
      setPictures(data?.pictures ?? null);
      setComment(data?.comment ?? "");
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // 日報の登録処理
  //--------------------------------------
  const saveData = async (): Promise<boolean> => {
    if (!selectedPark) return false;

    // ダイアログを開いている時に編集権限譲渡リクエストが来た場合
    fileListRef.current?.forceClose();
    pictureListRef.current?.forceClose();

    setErrorMessage("");

    if (files) {
      await processPictures(selectedPark.parkId, files);
    }

    if (pictures) {
      await processPictures(selectedPark.parkId, pictures);
    }

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const filesToSave = files?.filter((x) => !x.deleted) ?? [];
    const picturesToSave = pictures?.filter((x) => !x.deleted) ?? [];
    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      reportType,
      currentDate,
      {
        files: filesToSave,
        pictures: picturesToSave,
        comment,
      },
      pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }

      setFiles(filesToSave.length === 0 ? null : filesToSave);
      setPictures(picturesToSave.length === 0 ? null : picturesToSave);
      showToastMessage("日報の登録", msg);
      return true;
    } else {
      setErrorMessage("日報の保存に失敗しました。" + msg!);
      return false;
    }
  };

  //--------------------------------------
  // サブミット処理
  //--------------------------------------
  const onSave = async () => {
    await saveData();
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      await loadReportData();
    };
    f();
  }, [currentDate, reportType]); // eslint-disable-line react-hooks/exhaustive-deps

  //--------------------------------------
  // レンダリング
  //--------------------------------------
  return (
    <>
      <DashboardTemplate>
        <FormContainer size="max">
          {!selectedPark ? (
            <div> 公園が選択されていません </div>
          ) : (
            <>
              <PageTop
                title={pageTitle}
                reportType={reportType}
                tabId={TAB_ID.SPECIAL_NOTE}
                lastUpdated={lastUpdated}
                onEditStart={async () => await loadReportData()}
                onSave={!readOnly ? onSave : undefined}
                onCancel={async () => await loadReportData()}
              />
              <BaseForm>
                <Form.Label htmlFor="txtNotes" className="h5">
                  特記事項
                </Form.Label>
                <MyTextArea
                  id="txtNotes"
                  readonly={!isEditing}
                  initialValue={comment}
                  changed={(value) => {
                    setComment(value);
                  }}
                />
                <hr />
                <FileListCards
                  className="pb-3 px-2"
                  attr={{
                    title: "写真",
                    accepts: FILE_ACCEPTS_IMAGES,
                    usePicture: true,
                    imgHeight: "10rem",
                  }}
                  files={pictures}
                  ref={pictureListRef}
                  readonly={!isEditing}
                  listChanged={(newFiles) => setPictures(newFiles)}
                />
                <hr />
                <FileListCards
                  className="pb-3 px-2"
                  attr={{ title: "ファイル", accepts: FILE_ACCEPTS_ALL, usePicture: false }}
                  files={files}
                  ref={fileListRef}
                  readonly={!isEditing}
                  listChanged={(newFiles) => setFiles(newFiles)}
                />
              </BaseForm>
            </>
          )}
        </FormContainer>
      </DashboardTemplate>
    </>
  );
};

export default SpecialNotesPage;
