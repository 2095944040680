import { useRef, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";

import DraggableModal from "../molecules/DraggableModal";
import { ModalButtons } from "../molecules/ModalButtons";

type Props = {
  show: boolean;
  title: string;
  message: string;
  initialValue?: string;
  onOk: (title: string) => void;
  onCancel: () => void;
};

export const InputTextDlg = (props: Props) => {
  const { title, initialValue = "", message = "", show, onOk, onCancel } = props;
  const nodeRef = useRef();

  const [text, setText] = useState(initialValue);

  return (
    <Modal show={show} ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <FloatingLabel className="mb-2 my-2 " label="タイトル">
              <Form.Control
                type="text"
                placeholder={message}
                value={text}
                onChange={(e) => setText(e.target.value)}
                autoComplete="off"
              />
            </FloatingLabel>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ModalButtons
          okDisabled={!text}
          onOk={() => {
            onOk(text);
          }}
          okText="決定"
          onCancel={onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};
