import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Modal, Form } from "react-bootstrap";

import { WorkDefinition } from "../types/WorkReportMeta";
import { INVALID_ID, INVALID_ID_STR } from "common/types/consts/Defines";
import { NameID } from "common/types/NameID";
import DraggableModal from "common/components/molecules/DraggableModal";
import { ModalButtons } from "common/components/molecules/ModalButtons";

type Props = {
  index: number;
  initialValue?: NameID;
  workDefinitions: WorkDefinition[];
  tabName: string;
  onOK: (data: WorkDefinition) => void;
  onCancel: () => void;
};

type WorkTypeSlections = {
  name: string;
  candidates: WorkDefinition[];
};

const WORK_DEF_DELIMITER = "：";

export const SelectTaskDlg = (props: Props) => {
  const { index, initialValue, workDefinitions, tabName } = props;
  const nodeRef = useRef();
  const [selected, setSelected] = useState<WorkDefinition>();

  const [typeMajorList, setTypeMajorList] = useState<WorkTypeSlections[]>([]);
  const [typeMajor, setTypeMajor] = useState<string>();
  const [typeMediumList, setTypeMediumList] = useState<WorkTypeSlections[]>();
  const [typeMedium, setTypeMedium] = useState<string>();
  const [typeMinorList, setTypeMinorList] = useState<WorkTypeSlections[]>();
  const [typeMinor, setTypeMinor] = useState<string>();

  /**
   * 作業種別が確定した時の共通処理
   */
  const updateTaskType = (selected?: WorkDefinition) => {
    setSelected(selected);
  };

  const extractInitialSelections = () => {
    // 最初の作業種別を設定する
    const initialSelection: WorkTypeSlections[] = [];
    workDefinitions.forEach((x) => {
      if (x.tabs && x.tabs.find((tab) => tab === tabName) === undefined) return;

      const names = x.name.split(WORK_DEF_DELIMITER);
      const found = initialSelection.find((y) => y.name === names[0]);
      if (found) found.candidates.push(x);
      else initialSelection.push({ name: names[0], candidates: [x] });
    });
    return initialSelection;
  };

  const extractNextSelections = (
    name: string,
    list: WorkTypeSlections[],
    level: number,
    isLastLevel: boolean
  ) => {
    const nextTarget = list.find((x) => x.name === name);
    if (!nextTarget) return; // should not happen

    const nextList: WorkTypeSlections[] = [];
    nextTarget.candidates.forEach((x) => {
      const names = x.name.split(WORK_DEF_DELIMITER);
      if (names.length === 1) {
        nextList.push({ name: "", candidates: [x] });
        return;
      } else if (isLastLevel && names.length > level + 1) {
        // 3階層目は以降を一つにまとめる
        names[2] = names.splice(level).join(WORK_DEF_DELIMITER);
      }
      const itemInList = nextList.find((y) => y.name === names[level]);
      if (itemInList) itemInList.candidates.push(x);
      else nextList.push({ name: names[level], candidates: [x] });
    });
    return nextList;
  };

  /**
   * 大項目の選択
   */
  const typeMajorSelected = (name: string) => {
    setTypeMedium(undefined);
    setTypeMinor(undefined);
    setTypeMediumList(undefined);
    setTypeMinorList(undefined);

    if (name === INVALID_ID_STR) {
      setTypeMajor(undefined);
      updateTaskType(undefined);
      return;
    }
    setTypeMajor(name);
    const nextList = extractNextSelections(name, typeMajorList, 1, false);
    if (
      nextList?.length === 1 &&
      (nextList[0].candidates.length === 0 ||
        nextList[0].name === "" ||
        nextList[0].name === undefined)
    ) {
      // 決定
      updateTaskType(nextList[0].candidates[0]);
      return;
    }
    setTypeMediumList(nextList);
    updateTaskType(undefined);
  };

  /**
   * 中項目の選択
   */
  const typeMediumSelected = (name: string) => {
    setTypeMinor(undefined);
    setTypeMinorList(undefined);

    if (name === INVALID_ID_STR) {
      setTypeMedium(undefined);
      updateTaskType(undefined);
      return;
    }
    setTypeMedium(name);
    if (!typeMediumList) return; // should not happen
    const nextList = extractNextSelections(name, typeMediumList, 2, true);
    if (
      nextList?.length === 1 &&
      (nextList[0].candidates.length === 0 ||
        nextList[0].name === "" ||
        nextList[0].name === undefined)
    ) {
      // 決定
      updateTaskType(nextList[0].candidates[0]);
      return;
    }

    setTypeMinorList(nextList);
    updateTaskType(undefined);
  };

  const minorSelected = (name: string) => {
    if (name === INVALID_ID_STR) {
      setTypeMinor(undefined);
      return;
    }
    setTypeMinor(name);
    const found = typeMinorList?.find((x) => x.name === name);
    if (!found) return;
    updateTaskType(found.candidates[0]);
  };

  useEffect(() => {
    const initilaSelections = extractInitialSelections();
    setTypeMajorList(initilaSelections);

    // 選択状態の復元
    const selectedType = workDefinitions.find((x) => x.id === initialValue?.id);
    if (selectedType) {
      const names = selectedType.name.split(WORK_DEF_DELIMITER);
      setTypeMajor(names[0]);
      const mediums = extractNextSelections(names[0], initilaSelections, 1, false);
      if (mediums && mediums.length > 0) {
        setTypeMediumList(mediums);
        setTypeMedium(names.length > 1 ? names[1] : "");

        const minors = extractNextSelections(names[1], mediums, 2, true);
        if (minors && minors.length > 0) {
          setTypeMinorList(minors);
          setTypeMinor(names.length > 1 ? names[2] : "");
        }
      }
    }
    setSelected(selectedType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <Modal show={selected !== null} size="sm" ref={nodeRef} dialogAs={DraggableModal}>
      <Modal.Header className="handle">
        <Modal.Title>{index === INVALID_ID ? "作業の追加" : "作業の修正"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Sdiv>
          <SFormLabel>作業種別</SFormLabel>
          <Form.Select
            aria-label="作業種別"
            value={typeMajor}
            onChange={(e) => {
              e.preventDefault();
              typeMajorSelected(e.currentTarget.value);
            }}
          >
            <option value={INVALID_ID}>選択なし</option>
            {typeMajorList.map((x) => (
              <option key={`taskType1_${x.name}`} value={x.name}>
                {x.name === "" ? "-" : x.name}{" "}
                {x.candidates.length > 1 && `(${x.candidates.length})`}
              </option>
            ))}
          </Form.Select>
          {typeMediumList && (
            <Form.Select
              aria-label="作業種別"
              value={typeMedium}
              onChange={(e) => {
                e.preventDefault();
                typeMediumSelected(e.currentTarget.value);
              }}
            >
              <option value={INVALID_ID}>選択なし</option>
              {typeMediumList.map((x) => (
                <option key={`taskType2_${x.name}`} value={x.name}>
                  {x.name === "" ? "-" : x.name}{" "}
                  {x.candidates.length > 1 && `(${x.candidates.length})`}
                </option>
              ))}
            </Form.Select>
          )}
          {typeMinorList && (
            <Form.Select
              aria-label="作業種別"
              value={typeMinor}
              onChange={(e) => {
                e.preventDefault();
                minorSelected(e.currentTarget.value);
              }}
            >
              <option value={INVALID_ID}>選択なし</option>
              {typeMinorList.map((x) => (
                <option key={`taskType3_${x.name}`} value={x.name}>
                  {x.name === "" ? "-" : x.name}
                </option>
              ))}
            </Form.Select>
          )}
        </Sdiv>
      </Modal.Body>
      <Modal.Footer>
        <ModalButtons
          okDisabled={selected === undefined}
          onOk={() => {
            if (!selected) return;
            props.onOK(selected);
          }}
          okText="決定"
          onCancel={props.onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

const Sdiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

const SFormLabel = styled(Form.Label)`
  width: 100%;
  text-align: left;
`;
